import React from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

const SelectField = ({ children, ...props }) => {
	return (
		<TextField select {...props}>
			{children}
		</TextField>
	);
}

SelectField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,

	required: PropTypes.bool,
	autoFocus: PropTypes.bool,
	errorText: PropTypes.any,
}

export default SelectField;
