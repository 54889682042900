import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';

import SimplePage from '../../core/components/SimplePage';
import { EmpresaAPI, defaultProcessCatch } from '../../services/api';
import SelectComarca from '../../components/SelectComarca';
import SelectSituacaoProcesso from '../../components/SelectSituacaoProcesso';
import { TextField } from '../../core/components/material';
import EmpresaDialog from './EmpresaDialog';
import CardEmpresa from './CardEmpresa';

let timeoutRefreshEmpresas;

const ListaPublica = () => {
	const [filtros, setFiltros] = useState({
		comarca: '',
		situacao: '',
		nome: '',
	});
	const [filtroNome, setFiltroNome] = useState('');
	const [empresas, setEmpresas] = useState([]);
	const [dialog, setDialog] = useState(null);
	const handleChange = field => value => setFiltros({ ...filtros, [field]: value });

	useEffect(() => {
		EmpresaAPI.searchEmpresas(filtros)
			.then(rs => setEmpresas((rs.empresas || []).filter(e => e.ativo === true)))
			.catch(defaultProcessCatch());
	}, [filtros]);

	const handleChangeNome = value => {
		setFiltroNome(value);

		clearTimeout(timeoutRefreshEmpresas);
		timeoutRefreshEmpresas = setTimeout(() => {
			handleChange('nome')(value);
		}, 600);
	}

	const openEmpresa = empresa => setDialog(
		<EmpresaDialog
			empresa={empresa.uuid}
			onClose={() => setDialog(null)} />
	)

	return (
		<SimplePage disableAvatar maxWidth="md" displayAppBar showLogo={false}>
			<div style={{ marginBottom: 16 }}>
				<Grid container={true} spacing={2}>
					<Grid item={true} xs={12} md={5}>
						<TextField
							label="Filtro por Empresa"
							value={filtroNome}
							onChange={handleChangeNome} />
					</Grid>
					<Grid item={true} xs={12} sm={6} md={4}>
						<SelectComarca
							showAllOption={true}
							label="Comarca"
							value={filtros.comarca}
							onChange={handleChange('comarca')} />
					</Grid>
					<Grid item={true} xs={12} sm={6} md={3}>
						<SelectSituacaoProcesso
							showAllOption={true}
							label="Situação do Processo"
							value={filtros.situacao}
							onChange={handleChange('situacao')} />
					</Grid>
				</Grid>
			</div>

			<Grid container={true} spacing={2}>
				{empresas.map((empresa, index) => (
					<Grid key={index} item={true} xs={12} sm={6} md={4}>
						<CardEmpresa
							empresa={empresa}
							onClick={() => openEmpresa(empresa)} />
					</Grid>
				))}
			</Grid>

			{dialog}
		</SimplePage>
	);
}

export default ListaPublica;
