import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';

import {
	IconButton, Button, Tooltip, Typography,
	Table, TableHead, TableBody, TableRow, TableCell, makeStyles,
} from '@material-ui/core';
import {
	CloudDownload as DownloadIcon,
	Delete as RemoverIcon,
} from '@material-ui/icons';

import {
	ArquivoAPI, defaultProcessCatch,
	formatTimestamp, downloadArquivo, promptArquivo, EmpresaAPI, getArquivoPublicoLink,
} from '../../../services/api';
import Session from '../../../services/Session';
import Page from '../../../core/components/Page';
import SelecionarEmpresa from './SelecionarEmpresa';
import UploadDialog from './UploadDialog';
import alerts from '../../../utils/alerts';

const useStyles = makeStyles(theme => ({
	alert: {
		margin: theme.spacing(2),
	},
	uploadButton: {
		padding: theme.spacing(2),
		textAlign: 'right',
	},
}))

const ListarArquivos = () => {
	const { empresa: empresaParam } = useParams();
	const isAgente = Session.isAgente();
	const classes = useStyles();
	const [dialog, setDialog] = useState(null);
	const [empresa, setEmpresa] = useState(null);
	const [participante, setParticipante] = useState(null);
	const [arquivos, setArquivos] = useState([]);
	const [arquivosPublicos, setArquivosPublicos] = useState([]);
	const [autoSelect, setAutoSelect] = useState(true);

	const fetchArquivos = useCallback(() => {
		ArquivoAPI.listarArquivos(empresa.uuid)
			.then(rs => setArquivos(rs.arquivos || []))
			.catch(defaultProcessCatch());

		EmpresaAPI.listarArquivosPublicos(empresa.uuid)
			.then(rs => setArquivosPublicos(rs.arquivos || []))
			.catch(defaultProcessCatch());
	}, [empresa]);

	useEffect(() => {
		if (!empresa) {
			setArquivos([]);
			return;
		}
		fetchArquivos();
	}, [fetchArquivos, empresa]);

	const upload = () => {
		promptArquivo(files => setDialog(
			<UploadDialog empresaUUID={empresa.uuid}
				files={files}
				onFileUploaded={() => fetchArquivos()}
				onClose={() => {
					fetchArquivos();
					setDialog(null);
				}} />
		), true);
	}
	const download = arquivo => downloadArquivo(arquivo);
	const remover = arquivo => alerts.confirmYesNo(
		'Remover Arquivo',
		'Remover o arquivo do processo?',
		{
			onYes: () => {
				ArquivoAPI.removerArquivo(arquivo.uuid)
					.then(() => {
						alerts.snackbars.success('Arquivo removido com sucesso');
						fetchArquivos();
					})
					.catch(defaultProcessCatch());
			},
		});

	if (!empresa) {
		return (
			<SelecionarEmpresa
				onSelect={(e, p) => {
					setAutoSelect(false);
					setEmpresa(e);
					setParticipante(p);
				}}
				autoSelect={autoSelect}
				empresa={empresaParam} />
		);
	}

	const uploadAllowed = isAgente || (participante && participante.liberado);
	const arquivosCombinados = [
		...arquivos.map(a => ({ arquivo: a, publico: false, data_upload: a.data_upload })),
		...arquivosPublicos.map(a => ({ arquivo: a, publico: true, data_upload: a.data_cadastro }))
	].sort((a, b) => {
		const unixa = moment(a.data_upload).unix();
		const unixb = moment(b.data_upload).unix();
		if (unixa > unixb) return -1;
		if (unixb > unixa) return 1;
		return 0;
	});

	return (
		<Page title={`Empresa: ${empresa.razao_social}`}
			actions={
				<Button color="secondary" variant="outlined"
					onClick={() => upload()} disabled={!uploadAllowed}>
					Enviar Arquivos
				</Button>
			}
			action={
				<Button onClick={() => setEmpresa(null)}>
					Trocar Empresa
				</Button>
			}>

			{isAgente || (participante && participante.liberado)
				? (
					arquivosCombinados.length
						? (
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Nome</TableCell>
										<TableCell style={{ width: 220 }}>Proprietário</TableCell>
										<TableCell style={{ width: 170 }}>Tamanho do Arquivo</TableCell>
										<TableCell style={{ width: 200 }}>Data de Envio</TableCell>
										<TableCell style={{ width: 100 }}></TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{arquivosCombinados.map((a, index) => {
										const arquivo = a.arquivo;
										if (a.publico) {
											return (
												<TableRow key={index}>
													<TableCell>{arquivo.nome}</TableCell>
													<TableCell>-</TableCell>
													<TableCell>{prettyBytes(arquivo.tamanho)}</TableCell>
													<TableCell>{formatTimestamp(arquivo.data_cadastro)}</TableCell>
													<TableCell align="left" padding="checkbox">
														<Tooltip title="Baixar arquivo">
															<IconButton href={getArquivoPublicoLink(arquivo.uuid)} target="_blank">
																<DownloadIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>
											);
										}
										return (
											<TableRow key={index}>
												<TableCell>{arquivo.nome}</TableCell>
												<TableCell>
													{arquivo.usuario.uuid === Session.usuario.uuid
														? 'eu'
														: arquivo.usuario.nome_completo}
												</TableCell>
												<TableCell>{prettyBytes(arquivo.tamanho)}</TableCell>
												<TableCell>{formatTimestamp(arquivo.data_upload)}</TableCell>
												<TableCell align="left" padding="checkbox">
													<Tooltip title="Baixar arquivo">
														<IconButton onClick={() => download(arquivo)}>
															<DownloadIcon />
														</IconButton>
													</Tooltip>

													{arquivo.usuario.uuid === Session.usuario.uuid && !arquivo.recebimento.recebido
														? (
															<Tooltip title="Remover arquivo">
																<IconButton color="secondary" onClick={() => remover(arquivo)}>
																	<RemoverIcon />
																</IconButton>
															</Tooltip>
														) : null}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						)
						: (
							<Typography color="primary" align="center" className={classes.alert}>
								Você não possui nenhum arquivo para visualizar, e não enviou nenhum arquivo.
								<br />
								Clique no botão <strong>Enviar Arquivos</strong> para começar a enviar seus documentos.
							</Typography>
						)
				)
				: (
					<Typography color="secondary" align="center" className={classes.alert}>
						Seu acesso a essa empresa ainda não foi liberado, por favor,
						aguarde até que um dos administradores libere seu acesso.
					</Typography>
				)
			}

			{dialog}
		</Page>
	);
}

export default ListarArquivos;
