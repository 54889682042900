import { useLocation } from 'react-router-dom';

export function useQueryParams() {
	const search = `${useLocation().search}`.substr(1);
	const params = new URLSearchParams(search);

	let rs = {};
	params.forEach((v, k) => {
		rs[k] = v;
	});
	return rs;
}
