import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, CssBaseline, colors } from '@material-ui/core';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import AppPage from './pages/app/App';
import AuthLoginPage from './pages/auth/Login';
import AuthRegisterPage from './pages/auth/Register';
import AuthForgotPasswordPage from './pages/auth/ForgotPassword';
import AuthPasswordRecoveryPage from './pages/auth/PasswordRecovery';

import Session from './services/Session';
import EmpresaListaPublica from './pages/empresa/ListaPublica';
import { AlertBox, loadDefaultAlertBox } from './utils/alerts';

const theme = createMuiTheme({
	palette: {
		primary: colors.blue,
		secondary: colors.pink,
	},
});

const App = () => {
	const [loaded, setLoaded] = useState(false);

	const onMounted = obj => {
		loadDefaultAlertBox(obj);
		setLoaded(true);
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<AlertBox onMounted={onMounted} />

				{loaded ? (
					<Router>
						<Switch>
							<Route path="/" exact component={EmpresaListaPublica} />
							<Route path="/auth/login" exact component={AuthLoginPage} />
							<Route path="/auth/register" exact component={AuthRegisterPage} />
							<Route path="/auth/forgot-password" exact component={AuthForgotPasswordPage} />
							<Route path="/auth/password-recovery" exact component={AuthPasswordRecoveryPage} />

							<Route path="/app" component={AuthComponent} />
							<Redirect to="/" />
						</Switch>
					</Router>
				) : null}
			</ThemeProvider>
		</MuiPickersUtilsProvider>
	);
}

const AuthComponent = ({ location }) => {
	const [render, setRender] = useState(null);
	useEffect(() => {
		// console.log('checking authentication');
		Session.isAuthenticated()
			.then(authenticated => {
				// console.log('is authenticated', authenticated);
				setRender(authenticated
					? <AppPage />
					: (
						<Redirect
							to={{
								pathname: "/auth/login",
								state: { from: location }
							}}
						/>
					));
			})
			.catch(err => {
				console.error(err);
				setRender(
					<Redirect
						to={{
							pathname: "/auth/login",
							state: { from: location }
						}}
					/>
				);
			});
	}, [location]);
	return render;
}

export default App;
