import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, CardActions, makeStyles } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
	noPadding: { padding: '0 !important' },
	actions: { justifyContent: 'flex-end' },
}));

const Page = ({ title, action, actions, padding, children }) => {
	const classes = useStyle();

	return (
		<Card>
			<CardHeader title={title} action={action} />

			<CardContent className={classNames({ [classes.noPadding]: padding !== true })}>
				{children}
			</CardContent>

			{actions && (<CardActions className={classes.actions}>{actions}</CardActions>)}
		</Card>
	);
}

Page.propTypes = {
	title: PropTypes.string.isRequired,
	action: PropTypes.node,

	padding: PropTypes.bool.isRequired,
}

Page.defaultProps = {
	padding: false,
}

export default Page