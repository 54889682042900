import moment from 'moment';

import {
	ArquivoServiceApi,
	EmpresaServiceApi,
	UsuarioServiceApi,
	ProcessoServiceApi,

	DocsTipoUsuario as TipoUsuario,
} from './generated';


import { defaultProcessCatch, filterErrors, Errors } from './utils';

if (process.env.NODE_ENV !== 'production') {
	const imports = {
		ArquivoServiceApi,
		EmpresaServiceApi,
		UsuarioServiceApi,
		ProcessoServiceApi,

		TipoUsuario,
	};
	for (let name in imports) {
		if (!imports[name]) {
			throw new Error(`Módulo ${name} não encontrado`);
		}
	}
}

let config = { basePath: process.env.REACT_APP_API_ENDPOINT };
let usuario = new UsuarioServiceApi(config);
let empresa = new EmpresaServiceApi(config);
let arquivo = new ArquivoServiceApi(config);
let processo = new ProcessoServiceApi(config);

export function setAuthentication(token) {
	config.apiKey = token;
}

export function tipoPessoaText(tipo) {
	tipo = tipo || TipoUsuario.INDEFINIDO;
	switch (tipo) {
		case TipoUsuario.ESCRITORIO:
			return 'Escritório';
		case TipoUsuario.ADVOGADO:
			return 'Advogado';
		case TipoUsuario.PESSOAFISICA:
			return 'Não definido';
		default:
			return '- Indefinido -';
	}
}

export function formatTimestamp(data) {
	return moment(data).format('DD/MM/YYYY [às] HH:mm');
}

export function promptArquivo(onChange, multiple) {
	const input = document.createElement('input');
	input.style.display = 'none';
	input.type = 'file';
	input.multiple = multiple === true;
	input.onchange = event => onChange(event.target.files, event);

	document.body.appendChild(input);
	input.click();
	document.body.removeChild(input);
	input.remove();
}

export function uploadArquivo(empresa, arquivo) {
	const url = `${process.env.REACT_APP_ARQUIVOS_ENDPOINT}/empresas/${empresa}/arquivos`;
	return new Promise((resolve, reject) => {
		fetchArquivo(url, 'POST', { arquivo }, true)
			.then(res => {
				if (res.status >= 200 && res.status < 300) {
					resolve(res);
					return;
				}

				if (res.status >= 400) {
					res.json()
						.then(rs => reject(rs && rs.message));
					return;
				}

				reject(res);
			})
			.catch(err => reject(err));
	});
}

export function getArquivoPublicoLink(arquivo) {
	return `${process.env.REACT_APP_ARQUIVOS_ENDPOINT}/arquivos/publicos/${arquivo}/download`;
}

export function uploadArquivoPublico(empresa, arquivo, data) {
	const url = `${process.env.REACT_APP_ARQUIVOS_ENDPOINT}/empresas/${empresa}/arquivos/publicos`;
	return new Promise((resolve, reject) => {
		fetchArquivo(url, 'POST', { arquivo, data }, true)
			.then(res => {
				if (res.status >= 200 && res.status < 300) {
					resolve(res);
					return;
				}

				if (res.status >= 400) {
					res.json()
						.then(rs => reject(rs && rs.message));
					return;
				}

				reject(res);
			})
			.catch(err => reject(err));
	});
}

export function getLogoLink(empresa) {
	return `${process.env.REACT_APP_ARQUIVOS_ENDPOINT}/empresas/${empresa}/logo`;
}

export function uploadLogo(empresa, arquivo) {
	const url = `${process.env.REACT_APP_ARQUIVOS_ENDPOINT}/empresas/${empresa}/logo`;
	return new Promise((resolve, reject) => {
		fetchArquivo(url, 'POST', { arquivo }, true)
			.then(res => {
				if (res.status >= 200 && res.status < 300) {
					resolve(res);
					return;
				}
				if (res.status >= 400) {
					res.json()
						.then(rs => reject(rs && rs.message));
					return;
				}
				reject(res);
			})
			.catch(err => reject(err));
	});
}

export function downloadArquivo(arquivo) {
	const url = `${process.env.REACT_APP_ARQUIVOS_ENDPOINT}/arquivos/${arquivo.uuid}/download`;
	return new Promise((resolve, reject) => {
		fetchArquivo(url, 'GET')
			.then(res => {
				if (res.status === 200) {
					res.blob()
						.then(blob => {
							const blobURL = window.URL.createObjectURL(blob);
							let a = document.createElement('a');
							a.style.display = 'none';
							a.href = blobURL;
							a.download = arquivo.nome;
							document.body.appendChild(a);
							a.click();
							document.body.removeChild(a);
							a.remove();
						})
						.catch(err => reject(err));
					return;
				}
				reject(res);
			})
			.catch(err => reject(err));
	});
}

export function tipoPessoaIdentidadeText(tipo) {
	switch (tipo) {
		case TipoUsuario.ESCRITORIO:
			return 'CNPJ';
		case TipoUsuario.ADVOGADO:
			return 'OAB';
		case TipoUsuario.PESSOAFISICA:
			return 'CPF';
		default:
			return 'Identidade';
	}
}

export {
	arquivo as ArquivoAPI,
	empresa as EmpresaAPI,
	usuario as UsuarioAPI,
	processo as ProcessoAPI,

	TipoUsuario,

	defaultProcessCatch,
	filterErrors,
	Errors,
}

function fetchArquivo(resourceURL, method, data, hasFiles) {
	const isFormData = (method === 'POST' && hasFiles);
	let options = {
		method: method,
		headers: { Authorization: config.apiKey },
		mode: 'cors'
	};
	if (data) {
		if (isFormData) {
			if (typeof (data) !== 'object') {
				throw new Error('Para efetuar post de arquivos, é obrigatório o recebimento de "data" como objeto');
			}
			options.body = new FormData();
			let object = {};

			for (let index in data) {
				if (!data.hasOwnProperty(index)) {
					continue;
				}
				const value = data[index];
				if (value instanceof File) {
					options.body.append(index, value);
					continue;
				}
				if (value instanceof Array) {
					if (value.length === 0) {
						object[index] = value;
						continue;
					}
					const isFileList = (value[0] instanceof File);
					for (let indexFile = 0; indexFile < value.length; indexFile++) {
						const isFile = (value[indexFile] instanceof File);
						if ((isFile && !isFileList) || (!isFile && isFileList)) {
							throw new Error('A lista não pode possuir arquivos e outros valores junto.');
						}
					}
					if (isFileList) {
						for (let indexFile = 0; indexFile < value.length; indexFile++) {
							options.body.append(`${index}[${indexFile}]`, value[indexFile])
						}
					} else {
						object[index] = value;
					}
					continue;
				}

				object[index] = value;
			}
			options.body.append('json', new Blob(
				[JSON.stringify(object)],
				{ type: "application/json;charset=UTF-8" }
			));
		} else {
			options.body = JSON.stringify(data);
		}
	}
	return fetch(resourceURL, options);
}
