import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Grid, Button, makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import SimplePage from '../../core/components/SimplePage';
import { Form, TextField, Link } from '../../core/components/material';
import { UsuarioAPI, defaultProcessCatch, filterErrors } from '../../services/api';
import alerts from '../../utils/alerts';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const ForgotPassword = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [email, setEmail] = useState('');

	const [redirect, setRedirect] = useState(null);
	if (redirect) return redirect;

	const submit = () => {
		if (loading) return;
		setLoading(true);
		setErrors(null);

		UsuarioAPI.enviarRecuperacaoSenhaUsuario({ email })
			.finally(() => setLoading(false))
			.then(() => {
				alerts.info(
					'Recuperação de Senha',
					<React.Fragment>
						E-mail de recuperação de senha enviado.
						<br/>
						Siga as instruções no e-mail para redefinir sua senha.
					</React.Fragment>);
				setRedirect(<Redirect to="/auth/login" />);
			})
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	};

	return (
		<SimplePage title="Recuperação de Senha" maxWidth="sm" icon={<LockOutlinedIcon />}>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							autoFocus={true}
							label="Endereço de Email"
							value={email}
							onChange={v => setEmail(v)}
							errorText={filterErrors(errors, 'email')} />
					</Grid>
				</Grid>

				<Button className={classes.submit} type="submit"
					variant="contained" color="primary" fullWidth>
					Enviar e-mail
				</Button>
			</Form>

			<Grid container justify="flex-end">
				<Grid item>
					<Link to="/auth/login">
						Já possui uma conta? Entrar
					</Link>
				</Grid>
			</Grid>
		</SimplePage>
	);
}

export default ForgotPassword;
