import React, { useState, useEffect } from 'react';

import {
	Button, Dialog, DialogTitle, DialogActions, DialogContent,
	List, ListItem, ListItemText, DialogContentText,
} from '@material-ui/core';

import { EmpresaAPI, defaultProcessCatch } from '../../../services/api';
import Session from '../../../services/Session';
import Page from '../../../core/components/Page';
import { formatCNPJ } from '../../../utils/formatters';

const SelecionarEmpresa = ({ autoSelect, onSelect, empresa }) => {
	const [dialog, setDialog] = useState(null);
	const [participantes, setParticipantes] = useState({});
	const [empresas, setEmpresas] = useState([]);
	const [loadingEmpresas, setLoadingEmpresas] = useState(true);
	const [loadingParticipantes, setLoadingParticipantes] = useState(true);
	const isAgente = Session.isAgente();

	useEffect(() => {
		if (!isAgente) {
			EmpresaAPI.listarMinhasEmpresas()
				.finally(() => setLoadingParticipantes(false))
				.then(rs => {
					let participantes = {};
					for (const p of rs.participantes || []) {
						participantes[p.empresa.uuid] = p;
					}
					setParticipantes(participantes);
				})
				.catch(defaultProcessCatch());
		}

		EmpresaAPI.listarEmpresas()
			.then(rs => {
				if (isAgente) {
					setEmpresas(rs.empresas || []);
					setLoadingEmpresas(false);
					return;
				}
				setEmpresas((rs.empresas || []).filter(e => e.ativo === true));
				setLoadingEmpresas(false);
			})
			.catch(defaultProcessCatch());
	}, [isAgente]);

	useEffect(() => {
		if (!autoSelect) return;
		if (loadingEmpresas || (!isAgente && loadingParticipantes)) return;

		if (isAgente && empresas.length) {
			let filtered = [...empresas];
			if (empresa) {
				filtered = filtered.filter(e => e.uuid === empresa);
			}

			if (filtered.length === 1) {
				onSelect(filtered[0]);
				return;
			}
		}

		if (!isAgente) {
			if (empresa && participantes[empresa]) {
				const p = participantes[empresa];
				onSelect(p.empresa, p);
				return
			}

			const listParticipantes = Object.getOwnPropertyNames(participantes);
			if (listParticipantes.length === 1) {
				const p = participantes[listParticipantes[0]];
				onSelect(p.empresa, p);
				return;
			}
		}
	}, [isAgente, autoSelect, onSelect, empresa, empresas, participantes, loadingEmpresas, loadingParticipantes]);


	const solicitarAcesso = empresa => {
		EmpresaAPI.solicitarAcessoEmpresa(empresa.uuid)
			.then(rs => onSelect(empresa, rs))
			.catch(defaultProcessCatch());
	}

	const onSelectParticipante = (empresa, participante) => {
		if (participante) {
			onSelect(empresa, participante);
			return;
		}

		setDialog(
			<Dialog open={true}>
				<DialogTitle>Solicitação de Acesso</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Você não possui acesso desta empresa, deseja solicitar?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={() => setDialog(null)}>
						Não
					</Button>
					<Button variant="outlined" color="primary" onClick={() => solicitarAcesso(empresa)}>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Page title="Selecione uma empresa">
			<List>
				{empresas.map((e, index) => {
					if (isAgente) {
						return (
							<ListItem button key={index} onClick={() => onSelect(e)}>
								<ListItemText primary={`${e.razao_social} (${formatCNPJ(e.cnpj)})`} />
							</ListItem>
						);
					}

					const p = participantes[e.uuid];
					return (
						<ListItem button key={index} onClick={() => onSelectParticipante(e, p)}>
							<ListItemText
								primary={`${e.razao_social} (${e.numero_processo || formatCNPJ(e.cnpj)})`}
								secondary={(
									p
										? p.liberado
											? undefined
											: 'Solicitação de acesso pendente'
										: 'Você não possui acesso a esta empresa, clique para solicitar'
								)} />
						</ListItem>
					);
				})}
			</List>

			{dialog}
		</Page>
	);
}

export default SelecionarEmpresa;
