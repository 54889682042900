import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import TextField from './TextField';

function normalizeMask(mask) {
	if (typeof (mask) === 'function')
		return rawInput => normalizeMask(mask(rawInput));

	if (typeof (mask) !== 'string') return mask;

	const alphabet = {
		'9': /[0-9]/,
		'A': /[a-zA-Z]/,
		'*': /[a-zA-Z0-9]/,
	};

	let rs = [];
	for (let c of mask) {
		let n = alphabet[c];
		rs.push(n ? n : c);
	}
	return rs;
}

const InputMask = ({ inputRef, mask, ...props }) => {
	return (
		<MaskedInput {...props}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={normalizeMask(mask)}
			placeholderChar={'\u2000'} />
	);
};

InputMask.propTypes = {
	mask: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.array,
		PropTypes.func,
	]).isRequired,
}

const MaskedTextField = ({ mask, ...props }) => {
	return (
		<TextField {...props}
			inputProps={{ mask }}
			InputProps={{ inputComponent: InputMask }} />
	);
}

MaskedTextField.propTypes = {
	...TextField.propTypes,
	...InputMask.propTypes,
}

export default MaskedTextField;