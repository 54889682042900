import React, { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core';

import { ProcessoAPI, defaultProcessCatch } from '../services/api';
import { SelectField, MenuItem } from '../core/components/material';

function SelectSituacaoProcesso({ showAllOption, ...props }) {
	const [situacoes, setSituacoes] = useState([]);

	useEffect(() => {
		ProcessoAPI.listarSituacoesProcessos()
			.then(rs => setSituacoes(rs.situacoes || []))
			.catch(defaultProcessCatch());
	}, []);

	return (
		<SelectField {...props}>
			{showAllOption && <MenuItem value="">- Todas Situações -</MenuItem>}
			{showAllOption && <Divider />}

			{situacoes.map((s, index) => (
				<MenuItem key={index} value={s.uuid}>
					{s.nome}
				</MenuItem>
			))}
		</SelectField>
	);
}

export default SelectSituacaoProcesso;
