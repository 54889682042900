import React, { useState, useEffect } from 'react';

import {
	IconButton, Button,
	Table, TableHead, TableBody, TableRow, TableCell,
	Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

import AprovarIcon from '@material-ui/icons/DoneOutline';
import RecusarIcon from '@material-ui/icons/Block';

import { EmpresaAPI, defaultProcessCatch } from '../../../../services/api';
import Page from '../../../../core/components/Page';
import { formatCNPJ } from '../../../../utils/formatters';

const DialogConfirm = ({ title, children: content, onCancel = () => { }, onConfirm = () => { } }) => {
	return (
		<Dialog open={true}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				<Button onClick={() => onCancel()}>Cancelar</Button>
				<Button color="primary" variant="outlined" onClick={() => onConfirm()}>Confirmar</Button>
			</DialogActions>
		</Dialog>
	);
}

const ListaPendentes = () => {
	const [pendentes, setPendentes] = useState([]);
	const [dialog, setDialog] = useState(null);

	const fetchPendentes = () => {
		EmpresaAPI.listarParticipantesPendentes()
			.then(rs => setPendentes(rs.participantes || []))
			.catch(defaultProcessCatch());
	}
	useEffect(fetchPendentes, []);

	const aprovar = p => {
		setDialog(
			<DialogConfirm title="Aprovar Solicitação"
				onCancel={() => setDialog(null)}
				onConfirm={() => {
					EmpresaAPI.ativarParticipante(p.empresa.uuid, p.usuario.uuid)
						.then(() => {
							setDialog(null);
							fetchPendentes();
						})
						.catch(defaultProcessCatch());
				}}>
				Tem certeza de que deseja aprovar essa solicitação?
			</DialogConfirm>
		);
	}
	const recusar = p => {
		setDialog(
			<DialogConfirm title="Recusar Solicitação"
				onCancel={() => setDialog(null)}
				onConfirm={() => {
					EmpresaAPI.removerParticipante(p.empresa.uuid, p.usuario.uuid)
						.then(() => {
							setDialog(null);
							fetchPendentes();
						})
						.catch(defaultProcessCatch());
				}}>
				Tem certeza de que deseja recusar essa solicitação?
			</DialogConfirm>
		);
	}

	return (
		<Page title="Lista de Solicitações Pendentes">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Usuário</TableCell>
						<TableCell>Empresa</TableCell>
						<TableCell style={{ width: 120 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{pendentes.map((p, index) => (
						<TableRow key={index}>
							<TableCell>{p.usuario.nome_completo} ({p.usuario.email})</TableCell>
							<TableCell>{p.empresa.razao_social} ({formatCNPJ(p.empresa.cnpj)})</TableCell>
							<TableCell align="center" padding="checkbox">
								<IconButton onClick={() => recusar(p)}>
									<RecusarIcon color="secondary" />
								</IconButton>

								<IconButton onClick={() => aprovar(p)}>
									<AprovarIcon color="primary" />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
					{!pendentes.length && (
						<TableRow>
							<TableCell colSpan={3} align="center">
								Nenhum registro encontrado
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>

			{dialog}
		</Page>
	);
}

export default ListaPendentes;