import React from 'react';
import classNames from 'classnames';

import {
	Card, CardMedia, Typography,
	makeStyles, Button, colors, CardHeader,
	CardActionArea, CardActions,
} from '@material-ui/core';

import { getLogoLink } from '../../services/api';

const useStyles = makeStyles(() => ({
	media: {
		height: 80,
		backgroundSize: '90%',
	},
	noMedia: {
		backgroundColor: colors.grey[100],
		'& > p': {
			height: '100%',
			textAlign: 'center',
			fontSize: 30,
			paddingTop: 18,
			color: colors.grey[300],
			fontWeight: 600,
		},
	},
}))

const CardEmpresa = ({ empresa, onClick, ...props }) => {
	const classes = useStyles();

	return (
		<Card {...props}>
			<CardActionArea onClick={onClick}>
				{empresa.has_logo
					? (
						<CardMedia
							className={classes.media}
							image={getLogoLink(empresa.uuid)}
							title={empresa.razao_social} />
					)
					: (
						<div className={classNames([classes.media, classes.noMedia])}>
							<Typography>SEM LOGO</Typography>
						</div>
					)}

				<CardHeader
					title={empresa.razao_social} />
			</CardActionArea>


			<CardActions>
				<Button style={{ marginLeft: 'auto' }} color="secondary" onClick={onClick}>
					Ver detalhes
				</Button>
			</CardActions>
		</Card>
	);
}

export default CardEmpresa;
