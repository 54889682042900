export const bootstrapColors = {
	muted: '#777777',
	primary: '#337AB7',
	success: '#3C763D',
	info: '#31708F',
	warning: '#8A6D3B',
	danger: '#A94442',
};

export const bootstrapBackgroundColors = {
	primary: '#337AB7',
	success: '#DFF0D8',
	info: '#D9EDF7',
	warning: '#FCF8E3',
	danger: '#F2DEDE',
};
