import cookie from 'js-cookie';

class Cookie {
	constructor(defaultOptions) {
		let config = {
			secure: process.env.NODE_ENV === 'production',
		};
		if (config.secure) {
			config.domain = process.env.COOKIE_DOMAIN;
		}
		this.defaultOptions = Object.assign({ path: '/' }, defaultOptions || {});
		this.defaultOptions = Object.assign(this.defaultOptions, config);
	}

	get(name) {
		return cookie.getJSON(name);
	}

	save(name, value, options) {
		options = Object.assign(this.defaultOptions, options);
		cookie.set(name, value, options)
	}

	delete(name, options) {
		options = Object.assign(this.defaultOptions, options);
		cookie.remove(name, options);
	}
}

export default Cookie;

