import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Grid, Button, makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import SimplePage from '../../core/components/SimplePage';
import { Form, TextField, Checkbox, Link } from '../../core/components/material';

import Session from '../../services/Session';
import { UsuarioAPI, defaultProcessCatch, filterErrors } from '../../services/api';
import Cookie from '../../utils/cookie';

let cookie = new Cookie({ expires: 90 });

const useStyles = makeStyles(theme => ({
	form: {
		marginTop: theme.spacing(2),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	}
}));

const Login = () => {
	const classes = useStyles();
	const autoFocusPassword = !!cookie.get('docs.login.email');

	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		email: cookie.get('docs.login.email'),
		senha: '',
		lembrar: !!cookie.get('docs.login.email'),
	});

	const [errors, setErrors] = useState(null);
	const [redirect, setRedirect] = useState(null);
	if (redirect) return redirect;

	const handleChange = name => value => setState({ ...state, [name]: value });
	const submit = () => {
		if (loading) return;
		setLoading(true);

		UsuarioAPI.authUsuario({ email: state.email, senha: state.senha })
			.finally(() => setLoading(false))
			.then(rs => {
				if (state.lembrar) {
					cookie.save('docs.login.email', state.email);
				} else {
					cookie.delete('docs.login.email');
				}

				Session.setToken(rs.token);
				setRedirect(<Redirect to="/app" />);
			})
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	};

	return (
		<SimplePage title="Login" icon={<LockOutlinedIcon />}>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							required
							label="Endereço de Email"
							autoFocus={!autoFocusPassword}
							autoComplete="email"
							value={state.email}
							onChange={handleChange('email')}
							errorText={filterErrors(errors, 'email')} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							required
							label="Senha"
							type="password"
							autoFocus={autoFocusPassword}
							value={state.senha}
							onChange={handleChange('senha')}
							errorText={filterErrors(errors, 'senha')} />
					</Grid>
					<Grid item xs={12}>
						<Checkbox label="Lembrar-me"
							value={state.lembrar} onChange={handleChange('lembrar')} />
					</Grid>
				</Grid>

				<Button className={classes.submit} type="submit"
					variant="contained" color="primary" fullWidth>
					LOGIN
				</Button>
			</Form>

			<Grid container justify="space-between">
				<Grid item>
					<Link to="/auth/forgot-password">
						Recuperar senha
					</Link>
				</Grid>
				<Grid item>
					<Link to="/auth/register">
						Não possui uma conta? Registre-se
					</Link>
				</Grid>
			</Grid>
		</SimplePage>
	);
}

export default Login;
