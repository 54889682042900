import React, { useState, useEffect } from 'react';

import {
	IconButton, Button, Grid,
	Table, TableHead, TableBody, TableRow, TableCell,
	Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

import { Visibility as ViewIcon } from '@material-ui/icons';

import { Form, TextField } from '../../../../core/components/material';
import { EmpresaAPI, defaultProcessCatch, filterErrors } from '../../../../services/api';
import Page from '../../../../core/components/Page';

const CadastroTipoArquivo = ({ categoria = {}, onClose }) => {
	const [state, setState] = useState({
		nome: categoria.nome,
	});
	const [errors, setErrors] = useState(null);
	const handleChange = field => value => setState({ ...state, [field]: value });
	const modoAlteracao = !!categoria.uuid;

	const submit = () => {
		if (modoAlteracao) {
			EmpresaAPI.alterarCategoriaArquivo(categoria.uuid, { categoria: state })
				.then(() => onClose(true))
				.catch(defaultProcessCatch(errors => setErrors(errors)));
			return;
		}
		EmpresaAPI.cadastrarCategoriaArquivo({ categoria: state })
			.then(() => onClose(true))
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	};

	return (
		<Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth={true}>
			<DialogTitle>
				{modoAlteracao ? 'Alteração' : 'Cadastro'} de Tipo de Arquivo
			</DialogTitle>

			<Form onSubmit={submit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField label="Nome"
								autoFocus={true}
								value={state.nome}
								onChange={handleChange('nome')}
								errorText={filterErrors(errors, 'nome')} />
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>
						Cancelar
					</Button>
					<Button color="primary" variant="outlined" type="submit">
						{modoAlteracao ? 'Alterar' : 'Cadastrar'}
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

const ListaTiposArquivo = () => {
	const [categorias, setCategorias] = useState([]);
	const [dialog, setDialog] = useState(null)

	const fetchCategorias = () => {
		EmpresaAPI.listarCategoriasArquivos()
			.then(rs => setCategorias(rs.categorias || []))
			.catch(defaultProcessCatch());
	}
	useEffect(fetchCategorias, []);

	const openModal = categoria => setDialog(
		<CadastroTipoArquivo
			categoria={categoria}
			onClose={refresh => {
				if (refresh) fetchCategorias();
				setDialog(null);
			}} />
	)

	return (
		<Page title="Lista de Tipos de Arquivo"
			action={
				<Button variant="outlined" color="primary" onClick={() => openModal()}>
					Cadastrar Tipo
				</Button>
			}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Tipo</TableCell>
						<TableCell padding="none" style={{ width: 60 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{!categorias.length && (
						<TableRow>
							<TableCell align="center">
								- Nenhum tipo de arquivo cadastrado -
							</TableCell>
						</TableRow>
					)}
					{categorias.map((s, index) => (
						<TableRow key={index} hover>
							<TableCell>{s.nome}</TableCell>
							<TableCell align="center" padding="none">
								<IconButton onClick={() => openModal(s)}>
									<ViewIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			{dialog}
		</Page>
	);
}

export default ListaTiposArquivo;
