import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { RootRef, makeStyles, colors, Typography } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	root: {
		outline: 'none',
		userSelect: 'none',
		transition: 'all 0.1s ease-in',
		padding: theme.spacing(2, 2, 1),
		border: `2px dashed ${colors.grey[500]}`,
		backgroundColor: colors.grey[100],
		cursor: 'pointer',
		textAlign: 'center',

		'&:hover': {
			backgroundColor: colors.grey[300],
		},
	},

	success: {
		backgroundColor: colors.green[100],
		'&:hover': {
			backgroundColor: `${colors.green[300]} !important`,
		},
	},

	text: {
		fontSize: 16,
		textAlign: 'center',
	},

	content: {
		margin: theme.spacing(1, 0),
	},

	icon: {
		fontSize: 34,
		marginTop: theme.spacing(2),
	},
}))

const Dropzone = ({ children, color, text, acceptedFiles, multiple, onDrop }) => {
	const handleDrop = files => {
		if (acceptedFiles && acceptedFiles.length) {
			files = files.filter(f => {
				if (acceptedFiles.indexOf(f.type) > -1) return true;

				const type = `${f.type}`.split('/');
				if (type.length !== 2) return false;

				if (acceptedFiles.indexOf(`${type[0]}/*`) > -1) return true;
				if (acceptedFiles.indexOf('*/*') > -1) return true;
				if (acceptedFiles.indexOf('*') > -1) return true;
				return false;
			});
		}
		onDrop(files);
	}
	const classes = useStyles();
	const { getRootProps, getInputProps } = useDropzone({ multiple, onDrop: handleDrop });
	const { ref, ...rootProps } = getRootProps();

	return (
		<RootRef rootRef={ref}>
			<div {...rootProps}
				className={classNames(classes.root, {
					[classes.success]: color === 'success',
				})}>
				<input {...getInputProps()} />

				<Typography className={classes.text} variant="h5" component="h5">
					{text}
				</Typography>

				{children && <div className={classes.content}>{children}</div>}
				<div><CloudUpload className={classes.icon} /></div>
			</div>
		</RootRef>
	);
}

Dropzone.propTypes = {
	onDrop: PropTypes.func.isRequired,
	multiple: PropTypes.bool.isRequired,
	text: PropTypes.string.isRequired,
	color: PropTypes.oneOf(['default', 'success']),
	acceptedFiles: PropTypes.array,
}

Dropzone.defaultProps = {
	multiple: false,
	text: 'Arraste e solte arquivos aqui, ou clique para selecionar arquivos',
}

export default Dropzone;