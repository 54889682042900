import { setAuthentication, UsuarioAPI } from './api';
import Cookie from '../utils/cookie';

let cookie = new Cookie({ expires: 15 });

class Session {
	token = ''
	usuario = {}

	setToken(token) {
		this.token = token;
		setAuthentication(token);
		cookie.save('DOCS_TK', this.token);
	}

	logout() {
		return new Promise(resolve => {
			this.setToken(null);
			resolve();
		});
	}

	isAuthenticated() {
		return new Promise((resolve, reject) => {
			UsuarioAPI.usuarioLogado()
				.then(rs => {
					const authenticated = rs.authenticated === true;
					this.usuario = rs.usuario || {};
					resolve(authenticated);
				})
				.catch(rs => reject(rs));
		});
	}

	isAgente() {
		return (this.usuario && this.usuario.is_agente === true) || this.isAdmin();
	}

	isAdmin() {
		return this.usuario && this.usuario.is_admin === true;
	}
}

let s = new Session();
s.setToken(cookie.get('DOCS_TK'));
export default s;