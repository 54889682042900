import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Avatar, makeStyles, Button, AppBar, Toolbar } from '@material-ui/core';
import { Link, LinkRouter } from './material';

const useStyle = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	paper: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	content: {
		width: '100%',
		marginTop: theme.spacing(1),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(3),
	},
	logo: {
		margin: `${theme.spacing(5)}px auto`,
		textAlign: 'center',

		'& > a': {
			cursor: 'pointer',
		},

		'& > a > img': {
			maxWidth: 200,
			maxHeight: 85,
		}
	},
}));

const SimplePage = ({ title, icon, children, maxWidth, displayAppBar, showLogo = true }) => {
	const classes = useStyle();

	return (
		<React.Fragment>
			{displayAppBar && (
				<AppBar style={{ backgroundColor: 'transparent' }} elevation={0}>
					<Toolbar>
						<div style={{ flexGrow: 1 }}></div>

						<Button variant="outlined" component={LinkRouter} to="/auth/login">
							Entrar
						</Button>
					</Toolbar>
				</AppBar>
			)}

			{showLogo && (
				<div className={classes.logo}>
					<Link to="/">
						<img src="/logo.png" alt="Logo" />
					</Link>
				</div>
			)}

			<Container component="main" maxWidth={maxWidth}>
				<div className={classes.paper}>
					{icon && (
						<Avatar className={classes.avatar}>
							{icon}
						</Avatar>
					)}

					{title && (
						<Typography component="h1" variant="h5">
							{title}
						</Typography>
					)}

					<div className={classes.content}>
						{children}
					</div>
				</div>
			</Container>
		</React.Fragment>
	);
}

SimplePage.propTypes = {
	icon: PropTypes.any,
	title: PropTypes.string,
	maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	displayAppBar: PropTypes.bool,
}

SimplePage.defaultProps = {
	maxWidth: 'xs',
}

export default SimplePage
