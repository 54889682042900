import React, { useState, useEffect } from 'react'

import {
	Grid, Dialog, DialogTitle, DialogContent, DialogActions,
	Button, Divider, Typography, withWidth, Link, CardMedia, makeStyles, colors,
} from '@material-ui/core';

import { EmpresaAPI, defaultProcessCatch, ProcessoAPI, getArquivoPublicoLink, getLogoLink } from '../../services/api';
import { reduceByAttr } from '../../utils/reducer';

const Info = withWidth()(function Info({ title, value, width }) {
	if (!value) return null;
	return (
		<React.Fragment>
			<Grid item={true} xs={12} sm={4} md={3} style={{ textAlign: width === 'xs' ? 'left' : 'right' }}>
				<strong>{title}:</strong>
			</Grid>
			<Grid item={true} xs={12} sm={8} md={9}>{value}</Grid>
		</React.Fragment>
	);
});

function TimelineArquivos({ arquivos }) {
	const [categorias, setCategorias] = useState([]);

	useEffect(() => {
		EmpresaAPI.listarCategoriasArquivos()
			.then(rs => setCategorias(rs.categorias || []))
			.catch(defaultProcessCatch());
	}, []);

	return (
		<React.Fragment>
			{arquivos.filter(a => !a.categoria).map((a, index) => (
				<div key={index} style={{ margin: 4 }}>
					<Link href={getArquivoPublicoLink(a.uuid)} target="_blank">
						{a.descricao}
					</Link>
				</div>
			))}

			{categorias.map((c, index) => {
				const arquivosCategoria = arquivos.filter(a => a.categoria && a.categoria.uuid === c.uuid);
				if (!arquivosCategoria.length) return null;

				return (
					<div key={index} style={{ marginTop: 16, marginBottom: 16 }}>
						<Typography>{c.nome}</Typography>
						<Divider />

						{arquivosCategoria.map((a, index) => (
							<div key={index} style={{ margin: 4 }}>
								<Link href={getArquivoPublicoLink(a.uuid)} target="_blank">
									{a.descricao}
								</Link>
							</div>
						))}
					</div>
				);
			})}
		</React.Fragment>
	);
}

const useStyles = makeStyles(() => ({
	media: {
		height: 50,
		maxWidth: 250,
		backgroundSize: 'contain',
		backgroundPosition: 'top',
		marginLeft: 'auto',
	},
	noMedia: {
		backgroundColor: colors.grey[100],
		'& > p': {
			height: '100%',
			textAlign: 'center',
			fontSize: 30,
			paddingTop: 18,
			color: colors.grey[300],
			fontWeight: 600,
		},
	},
}))

function EmpresaDialog({ empresa: empresaUUID, onClose }) {
	const classes = useStyles();
	const [empresa, setEmpresa] = useState(null);
	const [arquivos, setArquivos] = useState([]);
	const [situacoes, setSituacoes] = useState({});

	useEffect(() => {
		ProcessoAPI.listarSituacoesProcessos()
			.then(rs => setSituacoes(reduceByAttr(rs.situacoes || [], 'uuid')))
			.catch(defaultProcessCatch());
	}, []);

	useEffect(() => {
		EmpresaAPI.getEmpresa(empresaUUID)
			.then(rs => setEmpresa(rs))
			.catch(defaultProcessCatch());

		EmpresaAPI.listarArquivosPublicos(empresaUUID)
			.then(rs => setArquivos(rs.arquivos || []))
			.catch(defaultProcessCatch());
	}, [empresaUUID]);

	if (!empresa) return null;

	return (
		<Dialog open={true} maxWidth="md" fullWidth={true} onClose={onClose}>
			<DialogTitle>
				<Grid container={true}>
					<Grid item={true} xs={6}>
						{empresa.razao_social}
					</Grid>
					<Grid item={true} xs={6}>
						{empresa.has_logo
							? (
								<CardMedia
									className={classes.media}
									image={getLogoLink(empresa.uuid)}
									title={empresa.razao_social} />
							)
							: null}
					</Grid>
				</Grid>
			</DialogTitle>

			<DialogContent>
				<Grid container={true} spacing={1}>
					<Info title="Número do Processo" value={empresa.numero_processo} />
					<Info title="Vara" value={empresa.vara} />
					<Info title="Comarca" value={empresa.comarca} />
					<Info title="Juiz" value={empresa.juiz} />
					{situacoes[empresa.situacao]
						? <Info title="Situação" value={situacoes[empresa.situacao].nome} />
						: null}
				</Grid>

				<Typography style={{ marginTop: 32 }} variant="h5" gutterBottom>
					Arquivos disponíveis
      			</Typography>
				<Divider />
				<br />

				{arquivos.length
					? <TimelineArquivos arquivos={arquivos} />
					: <p style={{ fontStyle: 'italic' }}>- Nenhum arquivo disponível -</p>}
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Fechar</Button>
			</DialogActions>
		</Dialog>
	);
}

export default EmpresaDialog;
