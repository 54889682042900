import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';

import {
	Button, Grid,
	Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Divider,
} from '@material-ui/core';

import { TextField, Dropzone, SelectField } from '../../../../core/components/material';
import { uploadArquivoPublico, EmpresaAPI, defaultProcessCatch } from '../../../../services/api';

const EnvioArquivoPublicoDialog = ({ empresa, onClose }) => {
	const [tiposArquivo, setTiposArquivo] = useState([]);
	const [files, setFiles] = useState([]);
	const [descricao, setDescricao] = useState(null);
	const [tipo, setTipo] = useState(null);
	const file = files && files.length === 1 ? files[0] : null;

	useEffect(() => {
		EmpresaAPI.listarCategoriasArquivos()
			.then(rs => setTiposArquivo(rs.categorias || []))
			.catch(defaultProcessCatch());
	}, []);

	const submit = () => {
		if (!descricao) {
			alert('Informe uma descrição para o arquivo');
			return;
		}
		if (!file) {
			alert('Selecione um arquivo para enviar');
			return;
		}

		uploadArquivoPublico(empresa.uuid, file, { descricao, tipo })
			.then(() => onClose(true))
			.catch(err => {
				console.error(err);
				alert(`Erro ao enviar arquivo: ${err}`);
			});
	}

	return (
		<Dialog open={true} maxWidth="sm" fullWidth onClose={() => onClose()}>
			<DialogTitle>Envio de arquivos para {empresa.razao_social}</DialogTitle>

			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							label="Descrição"
							value={descricao}
							onChange={v => setDescricao(v)} />
					</Grid>

					<Grid item xs={12}>
						<SelectField
							label="Tipo de Arquivo"
							value={tipo}
							onChange={v => setTipo(v)}>
							<MenuItem>- Não selecionado -</MenuItem>
							<Divider />
							{tiposArquivo.map((t, index) => (
								<MenuItem key={index} value={t.uuid}>{t.nome}</MenuItem>
							))}
						</SelectField>
					</Grid>

					<Grid item xs={12}>
						<Dropzone
							onDrop={files => setFiles(files)}
							color={files.length > 0 ? 'success' : 'default'}>
							{files.map((f, index) => (
								<div key={index}>
									<strong>Selecionado:</strong> {f.name} ({prettyBytes(f.size)})
								</div>
							))}
						</Dropzone>
					</Grid>
				</Grid>
			</DialogContent >

			<DialogActions>
				<Button variant="outlined" onClick={() => onClose()}>
					Cancelar
				</Button>

				<Button variant="outlined" color="primary" onClick={() => submit()}>
					Enviar
				</Button>
			</DialogActions>
		</Dialog >
	);
}

EnvioArquivoPublicoDialog.propTypes = {
	empresa: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default EnvioArquivoPublicoDialog;
