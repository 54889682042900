import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { Form, TextField } from '../../../../core/components/material';
import { filterErrors, defaultProcessCatch, UsuarioAPI, Errors } from '../../../../services/api';
import alerts from '../../../../utils/alerts';

const TrocarSenhaDialog = ({ onClose }) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [state, setState] = useState({
		senha_atual: '',
		nova_senha: '',
	});
	const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState('');

	const submit = () => {
		if (state.nova_senha !== confirmacaoNovaSenha) {
			setErrors((new Errors()).addFieldViolation(
				'confirmar_nova_senha',
				'Confirmação de senha deve ser igual a nova senha'));
			return;
		}
		if (loading) return;
		setLoading(true);

		UsuarioAPI.trocarSenhaUsuario(state)
			.finally(() => setLoading(false))
			.then(() => {
				alerts.snackbars.success('Senha alteradao com sucesso');
				onClose();
			})
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	}

	const handleChange = field => value => setState({ ...state, [field]: value });
	return (
		<Dialog open={true} onClose={onClose}>
			<DialogTitle>Trocar Senha</DialogTitle>

			<Form onSubmit={submit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								type="password"
								label="Senha Atual"
								value={state.senha_atual}
								onChange={handleChange('senha_atual')}
								errorText={filterErrors(errors, ['senha_atual'])} />
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="password"
								label="Nova Senha"
								value={state.nova_senha}
								onChange={handleChange('nova_senha')}
								errorText={filterErrors(errors, ['nova_senha'])} />
						</Grid>
						<Grid item xs={12}>
							<TextField
								type="password"
								label="Confirmar Nova Senha"
								value={confirmacaoNovaSenha}
								onChange={v => setConfirmacaoNovaSenha(v)}
								errorText={filterErrors(errors, ['confirmar_nova_senha'])} />
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button disabled={loading} onClick={onClose}>
						Fechar
					</Button>
					<Button color="primary" variant="outlined" type="submit" disabled={loading}>
						Trocar Senha
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

TrocarSenhaDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
}

export default TrocarSenhaDialog;
