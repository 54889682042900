import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox as StdCheckbox, FormControlLabel } from '@material-ui/core';

const Checkbox = ({ label, value, onChange, color, disabled }) => {
  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      control={(
        <StdCheckbox color={color} checked={value === true}
          onChange={event => onChange(event.target.checked, event)} />
      )} />
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,

  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
  disabled: PropTypes.bool,
}

export default Checkbox;