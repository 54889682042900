import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { Form, TextField, RadioGroup, RadioGroupItem } from '../../../../core/components/material';
import { filterErrors, defaultProcessCatch, UsuarioAPI, TipoUsuario, tipoPessoaIdentidadeText } from '../../../../services/api';
import { formatCPFOrCNPJ } from '../../../../utils/formatters';

const VisualizarUsuarioDialog = ({ usuarioUUID, onClose }) => {
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [state, setState] = useState({
		nome: '',
		sobrenome: '',
		email: '',

		tipo: '',
		identificador: '',
	});

	const fetchUsuario = useCallback(() => {
		setErrors(null);
		UsuarioAPI.getUsuario(usuarioUUID)
			.then(rs => setState({
				nome: rs.nome,
				sobrenome: rs.sobrenome,
				email: rs.email,
				tipo: rs.tipo,
				identificador: rs.identificador,
			}))
			.catch(defaultProcessCatch());
	}, [usuarioUUID])

	useEffect(() => {
		fetchUsuario();
	}, [fetchUsuario, usuarioUUID]);

	const submit = () => {
		if (loading) return;
		setLoading(true);


		UsuarioAPI.atualizarUsuario(usuarioUUID,
			{
				usuario: {
					nome: state.nome,
					sobrenome: state.sobrenome,
				},
			})
			.finally(() => setLoading(false))
			.then(rs => fetchUsuario())
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	}

	const handleChange = field => value => setState({ ...state, [field]: value });
	return (
		<Dialog open={true} onClose={onClose}>
			<DialogTitle>Meu Perfil</DialogTitle>

			<Form onSubmit={submit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField label="Nome" autoFocus={true}
								value={state.nome} onChange={handleChange('nome')}
								errorText={filterErrors(errors, ['usuario', 'usuario.nome'])} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField label="Sobrenome"
								value={state.sobrenome} onChange={handleChange('sobrenome')}
								errorText={filterErrors(errors, ['usuario', 'usuario.sobrenome'])} />
						</Grid>

						<Grid item xs={12}>
							<TextField label="Endereço de Email" autoComplete="email" disabled
								value={state.email} onChange={handleChange('email')}
								errorText={filterErrors(errors, ['usuario', 'usuario.email'])} />
						</Grid>

						{state.tipo !== TipoUsuario.INDEFINIDO && (
							<React.Fragment>
								<Grid item xs={12}>
									<RadioGroup label="Tipo de Pessoa" direction="row"
										justify="space-around" value={state.tipo} disabled>
										<RadioGroupItem value={TipoUsuario.ESCRITORIO} placement="end">
											Escritório
										</RadioGroupItem>
										<RadioGroupItem value={TipoUsuario.ADVOGADO} placement="end">
											Advogado
										</RadioGroupItem>
										<RadioGroupItem value={TipoUsuario.PESSOAFISICA} placement="end">
											Não informado
										</RadioGroupItem>
									</RadioGroup>
								</Grid>

								<Grid item xs={12}>
									<TextField label={tipoPessoaIdentidadeText(state.tipo)} disabled
										value={
											state.tipo === TipoUsuario.ADVOGADO
												? state.identificador
												: formatCPFOrCNPJ(state.identificador)
										} />
								</Grid>
							</React.Fragment>
						)}
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button disabled={loading} onClick={onClose}>
						Fechar
					</Button>
					<Button color="primary" variant="outlined" type="submit" disabled={loading}>
						Atualizar
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

VisualizarUsuarioDialog.propTypes = {
	usuarioUUID: PropTypes.string,
	onClose: PropTypes.func.isRequired,
}

export default VisualizarUsuarioDialog;