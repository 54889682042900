import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, Radio } from '@material-ui/core';

const RadioGroupItem = ({ value, children: label, color, placement }) => {
	return (
		<FormControlLabel value={value}
			label={label} labelPlacement={placement}
			control={<Radio color={color} />} />
	);
}

RadioGroupItem.propTypes = {
	children: PropTypes.node.isRequired,
	value: PropTypes.string.isRequired,

	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	placement: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
}

export default RadioGroupItem;