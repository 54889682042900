import React, { useState, useEffect } from 'react';
import { Divider } from '@material-ui/core';

import { ProcessoAPI, defaultProcessCatch } from '../services/api';
import { SelectField, MenuItem } from '../core/components/material';

function SelectComarca({ showAllOption, ...props }) {
	const [comarcas, setComarcas] = useState([]);

	useEffect(() => {
		ProcessoAPI.listarComarcas()
			.then(rs => setComarcas(rs.comarcas || []))
			.catch(defaultProcessCatch());
	}, []);

	return (
		<SelectField {...props}>
			{showAllOption && <MenuItem value="">- Todas Comarcas -</MenuItem>}
			{showAllOption && <Divider />}

			{comarcas.map((c, index) => (
				<MenuItem key={index} value={c.nome}>
					{c.nome}
				</MenuItem>
			))}
		</SelectField>
	);
}

export default SelectComarca;
