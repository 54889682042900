import React, { useState, useEffect } from 'react';

import {
	IconButton, Button, Grid,
	Table, TableHead, TableBody, TableRow, TableCell,
	Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';

import { Visibility as ViewIcon } from '@material-ui/icons';

import { Form, TextField } from '../../../../core/components/material';
import { ProcessoAPI, defaultProcessCatch, filterErrors } from '../../../../services/api';
import Page from '../../../../core/components/Page';

const CadastroSituacaoProcesso = ({ situacao = {}, onClose }) => {
	const [state, setState] = useState({
		nome: situacao.nome,
	});
	const [errors, setErrors] = useState(null);
	const handleChange = field => value => setState({ ...state, [field]: value });
	const modoAlteracao = !!situacao.uuid;

	const submit = () => {
		if (modoAlteracao) {
			ProcessoAPI.alterarSituacaoProcesso(situacao.uuid, { situacao: state })
				.then(() => onClose(true))
				.catch(defaultProcessCatch(errors => setErrors(errors)));
			return;
		}
		ProcessoAPI.cadastrarSituacaoProcesso({ situacao: state })
			.then(() => onClose(true))
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	};

	return (
		<Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth={true}>
			<DialogTitle>
				{modoAlteracao ? 'Alteração' : 'Cadastro'} de Situação de Processo
			</DialogTitle>

			<Form onSubmit={submit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField label="Nome"
								autoFocus={true}
								value={state.nome}
								onChange={handleChange('nome')}
								errorText={filterErrors(errors, 'nome')} />
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={onClose}>
						Cancelar
					</Button>
					<Button color="primary" variant="outlined" type="submit">
						{modoAlteracao ? 'Alterar' : 'Cadastrar'}
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

const ListaSituacoesProcesso = () => {
	const [situacoes, setSituacoes] = useState([]);
	const [dialog, setDialog] = useState(null)

	const fetchSituacoes = () => {
		ProcessoAPI.listarSituacoesProcessos()
			.then(rs => setSituacoes(rs.situacoes || []))
			.catch(defaultProcessCatch());
	}
	useEffect(fetchSituacoes, []);

	const openModal = situacao => setDialog(
		<CadastroSituacaoProcesso
			situacao={situacao}
			onClose={refresh => {
				if (refresh) fetchSituacoes();
				setDialog(null);
			}} />
	)

	return (
		<Page title="Lista de Situações de Processo"
			action={
				<Button variant="outlined" color="primary" onClick={() => openModal()}>
					Cadastrar Situação
				</Button>
			}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Situação</TableCell>
						<TableCell padding="none" style={{ width: 60 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{!situacoes.length && (
						<TableRow>
							<TableCell align="center">
								- Nenhuma situação de processo cadastrado -
							</TableCell>
						</TableRow>
					)}
					{situacoes.map((s, index) => (
						<TableRow key={index} hover>
							<TableCell>{s.nome}</TableCell>
							<TableCell align="center" padding="none">
								<IconButton onClick={() => openModal(s)}>
									<ViewIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			{dialog}
		</Page>
	);
}

export default ListaSituacoesProcesso;
