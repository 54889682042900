import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem } from '@material-ui/core';

const ListItemLink = ({ to, ...props }) => {
	const renderLink = React.useMemo(
		() =>
			React.forwardRef((itemProps, ref) => (
				// With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
				// See https://github.com/ReactTraining/react-router/issues/6056
				<RouterLink to={to} {...itemProps} innerRef={ref} />
			)),
		[to],
	);
	return <ListItem component={renderLink} {...props} />;
}

ListItemLink.propTypes = {
	to: PropTypes.string.isRequired,
}

export default ListItemLink;