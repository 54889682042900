import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Grid, Button, makeStyles } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import SimplePage from '../../core/components/SimplePage';
import { Form, TextField, Link } from '../../core/components/material';
import { UsuarioAPI, defaultProcessCatch, filterErrors, Errors } from '../../services/api';
import { useQueryParams } from '../../utils/hooks';
import alerts from '../../utils/alerts';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const PasswordRecovery = () => {
	const { email, token } = useQueryParams();

	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [novaSenha, setNovaSenha] = useState('');
	const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('');

	const [redirect, setRedirect] = useState(null);
	if (redirect) return redirect;

	const submit = () => {
		if (loading) return;

		setErrors(null);
		if (novaSenha !== confirmarNovaSenha) {
			setErrors((new Errors()).addFieldViolation(
				'confirmar_nova_senha',
				'Confirmação de senha deve ser igual a nova senha'));
			setLoading(false);
			return;
		}

		setLoading(true);

		UsuarioAPI.recuperacaoSenhaUsuario({ email, token, nova_senha: novaSenha })
			.finally(() => setLoading(false))
			.then(() => {
				alerts.snackbars.success('Senha alterada com sucesso');
				setRedirect(<Redirect to="/auth/login" />);
			})
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	};

	return (
		<SimplePage title="Alterar Senha" maxWidth="sm" icon={<LockOutlinedIcon />}>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							autoFocus={true}
							type="password"
							label="Nova Senha"
							value={novaSenha}
							onChange={v => setNovaSenha(v)}
							errorText={filterErrors(errors, 'nova_senha')} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							type="password"
							label="Confirmar nova senha"
							value={confirmarNovaSenha}
							onChange={v => setConfirmarNovaSenha(v)}
							errorText={filterErrors(errors, 'confirmar_nova_senha')} />
					</Grid>
				</Grid>

				<Button className={classes.submit} type="submit"
					variant="contained" color="primary" fullWidth>
					Confirmar nova senha
				</Button>
			</Form>

			<Grid container justify="flex-end">
				<Grid item>
					<Link to="/auth/login">
						Já possui uma conta? Entrar
					</Link>
				</Grid>
			</Grid>
		</SimplePage>
	);
}

export default PasswordRecovery;
