import React, { useState, useEffect } from 'react';

import {
	IconButton, Tooltip,
	Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';

import {
	DoneOutline as AprovarIcon,
	CloudDownload as DownloadIcon,
} from '@material-ui/icons';

import { defaultProcessCatch, ArquivoAPI, downloadArquivo, formatTimestamp } from '../../../services/api';
import Page from '../../../core/components/Page';
import { formatCNPJ } from '../../../utils/formatters';
import alerts from '../../../utils/alerts';

const ListaPendentes = () => {
	const [pendentes, setPendentes] = useState([]);

	const fetchPendentes = () => {
		ArquivoAPI.listarArquivosPendentes()
			.then(rs => setPendentes(rs.arquivos || []))
			.catch(defaultProcessCatch());
	}
	useEffect(fetchPendentes, []);

	const download = arquivo => downloadArquivo(arquivo);

	const marcarRecebido = p => alerts.confirmYesNo(
		'Aprovação de Arquivo',
		'Confirmar arquivo como recebido?',
		{
			onYes: () => {
				ArquivoAPI.marcarArquivoRecebido(p.uuid, {})
					.then(() => {
						alerts.snackbars.success('Arquivo marcado como recebido');
						fetchPendentes();
					})
					.catch(defaultProcessCatch());
			},
		})

	return (
		<Page title="Lista de Arquivos Pendentes">
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Arquivo</TableCell>
						<TableCell>Usuário</TableCell>
						<TableCell>Empresa</TableCell>
						<TableCell style={{ width: 230 }}>Processo</TableCell>
						<TableCell style={{ width: 120 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{pendentes.map((p, index) => (
						<TableRow key={index} hover={true}>
							<TableCell>
								{p.nome}
								<br /><strong>Data de Envio:</strong> {formatTimestamp(p.data_upload)}
							</TableCell>
							<TableCell>
								{p.usuario.nome_completo}
								<br />
								<strong>Email: </strong> {p.usuario.email}
							</TableCell>
							<TableCell>
								{p.empresa.razao_social}
								<br />
								<strong>CNPJ: </strong> {formatCNPJ(p.empresa.cnpj)}
							</TableCell>
							<TableCell>{p.empresa.numero_processo}</TableCell>
							<TableCell align="center" padding="checkbox">
								<Tooltip title="Baixar arquivo">
									<IconButton onClick={() => download(p)}>
										<DownloadIcon />
									</IconButton>
								</Tooltip>

								<Tooltip title="Marcar como Recebido">
									<IconButton onClick={() => marcarRecebido(p)}>
										<AprovarIcon color="primary" />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
					{!pendentes.length && (
						<TableRow>
							<TableCell colSpan={4} align="center">
								Nenhum registro encontrado
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</Page>
	);
}

export default ListaPendentes;
