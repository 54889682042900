import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';

import {
	Button, CircularProgress,
	Table, TableHead, TableBody, TableRow, TableCell,
	Dialog, DialogTitle, DialogActions, DialogContent, makeStyles,
} from '@material-ui/core';

import { uploadArquivo } from '../../../services/api';

const useStyles = makeStyles(theme => ({
	content: { padding: 0 },
	fileNameColumn: {
		maxWidth: 380,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
}))

const UploadDialog = ({ empresaUUID, files = [], onClose, onFileUploaded }) => {
	const classes = useStyles();
	const [arquivos, setArquivos] = useState([]);
	const [current, setCurrent] = useState(-1);

	useEffect(() => {
		let arqs = [];
		for (let i = 0; i < files.length; i++) {
			arqs.push(files[i]);
		}
		setArquivos(arqs);

		if (arqs.length) {
			setCurrent(0);
			return;
		}
		onClose();
	}, [files, onClose]);

	useEffect(() => {
		if (current < 0) return;
		if (current > arquivos.length - 1) {
			if (onFileUploaded) onFileUploaded();
			return;
		}

		uploadArquivo(empresaUUID, arquivos[current])
			.then(() => setCurrent(current + 1))
			.catch(err => {
				console.error(err);
				setCurrent(current + 1);
				alert('Erro ao enviar arquivo: ' + arquivos[current].name);
			});
	}, [empresaUUID, current, arquivos, onFileUploaded]);

	const finish = current > arquivos.length - 1;
	return (
		<Dialog open fullWidth maxWidth="sm">
			<DialogTitle>Envio de arquivos</DialogTitle>

			<DialogContent className={classes.content}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Arquivo</TableCell>
							<TableCell style={{ width: 120 }}>Tamanho</TableCell>
							<TableCell align="center" style={{ width: 100 }}>Situação</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{arquivos.map((f, index) => (
							<TableRow key={index}>
								<TableCell className={classes.fileNameColumn}>
									{f.name}
								</TableCell>
								<TableCell>{prettyBytes(f.size)}</TableCell>
								<TableCell padding="none" align="center">
									{current < index
										? 'Pendente'
										: current === index
											? <CircularProgress size={16} color="secondary" />
											: 'Concluído'}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>

			<DialogActions>
				{finish
					? (
						<Button color="primary" variant="outlined" onClick={() => onClose()}>
							Concluído
						</Button>
					)
					: (
						<Button color="secondary" variant="contained" onClick={() => onClose()}>
							Cancelar
						</Button>
					)}
			</DialogActions>
		</Dialog >
	);
}

UploadDialog.propTypes = {
	empresaUUID: PropTypes.string.isRequired,
	files: PropTypes.any.isRequired,
	onClose: PropTypes.func.isRequired,
	onFileUploaded: PropTypes.func.isRequired,
}

UploadDialog.defaultProps = {
	onFileUploaded: () => { },
}

export default UploadDialog;