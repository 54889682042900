import React, { useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import {
	Drawer, AppBar, Toolbar, IconButton,
	List, ListItem, ListItemIcon, ListItemText,
	Menu, MenuItem, Divider, makeStyles, Collapse
} from '@material-ui/core';

import {
	AccountCircle as AccountCircleIcon,
	SettingsApplications as ConfiguracoesIcon,
	Apartment as EmpresasIcon,
	SupervisorAccount as UsuariosIcon,
	CloudQueue as ArquivosIcon,

	ExpandLess,
	ExpandMore,
	Gavel,
	FileCopyOutlined,
	QueryBuilder,
} from '@material-ui/icons';

import { ListItemLink } from '../../core/components/material';

import Session from '../../services/Session';
import AppRouter from './AppRouter';
import VisualizarUsuarioDialog from './configuracoes/usuarios/VisualizarUsuarioDialog';
import TrocarSenhaDialog from './configuracoes/usuarios/TrocarSenhaDialog';

const drawerWidth = 280;
const useStyles = makeStyles(theme => {
	const toolbarHeight = 48;
	return {
		'@global': {
			body: {
				backgroundColor: '#efefef',
			},
		},

		appBar: {
			zIndex: theme.zIndex.drawer + 1,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			width: drawerWidth,
		},
		toolbar: { minHeight: toolbarHeight },
		title: {
			'& > img': {
				height: 38,
				marginTop: 4,
				filter: 'brightness(0) invert(1)',
			},
			flexGrow: 1,
		},

		content: {
			padding: theme.spacing(1),
			overflowX: 'auto',
			position: 'fixed',
			right: 0,
			bottom: 0,
			top: toolbarHeight,
			left: drawerWidth,
		},

		nested: {
			paddingLeft: theme.spacing(4),
		},
	};
});

const App = ({ location }) => {
	const classes = useStyles();
	const [dialog, setDialog] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [redirect, setRedirect] = useState(null);
	const [configuracoesOpen, setConfiguracoesOpen] = useState(false);
	const [pendenciasOpen, setPendenciasOpen] = useState(true);
	if (redirect) return redirect;

	const logout = () => {
		Session.logout()
			.then(() => setRedirect(<Redirect to="/" />));
	}

	const abrirPerfil = () => {
		setAnchorEl(null);
		setDialog(
			<VisualizarUsuarioDialog
				usuarioUUID={Session.usuario.uuid}
				onClose={() => setDialog(null)} />
		);
	}

	const trocarSenha = () => {
		setAnchorEl(null);
		setDialog(
			<TrocarSenhaDialog
				onClose={() => setDialog(null)} />
		);
	}

	const linkProps = pathname => ({
		to: pathname,
		selected: pathname === location.pathname,
	})

	return (
		<React.Fragment>
			<AppBar position="fixed" className={classes.appBar} elevation={1}>
				<Toolbar variant="dense">
					<div className={classes.title}>
						<img alt="Logo" src="/logo.png" />
					</div>

					<div>
						<IconButton color="inherit"
							onClick={event => setAnchorEl(event.currentTarget)}>
							<AccountCircleIcon />
						</IconButton>

						<Menu
							anchorEl={anchorEl}
							open={!!anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							onClose={() => setAnchorEl(null)}>
							<MenuItem onClick={abrirPerfil}>Meu Perfil</MenuItem>
							<MenuItem onClick={trocarSenha}>Trocar Senha</MenuItem>
							<Divider />
							<MenuItem onClick={logout}>Sair</MenuItem>
						</Menu>
					</div>
				</Toolbar>
			</AppBar>

			<Drawer open={true} variant="permanent"
				className={classes.drawer}
				classes={{ paper: classes.drawerPaper }}>
				<div className={classes.toolbar}></div>
				<List>
					<ListItemLink {...linkProps('/app/arquivos')} button>
						<ListItemIcon>
							<ArquivosIcon />
						</ListItemIcon>
						<ListItemText primary="Arquivos" />
					</ListItemLink>

					{Session.isAdmin() && (
						<React.Fragment>
							<ListItem button onClick={() => setPendenciasOpen(!pendenciasOpen)}>
								<ListItemIcon>
									<QueryBuilder />
								</ListItemIcon>
								<ListItemText primary="Pendências" />
								{pendenciasOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItem>

							<Collapse in={pendenciasOpen} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<ListItemLink {...linkProps('/app/configuracoes/participantes/pendentes')}
										button className={classes.nested}>
										<ListItemIcon>
											<UsuariosIcon />
										</ListItemIcon>
										<ListItemText primary="Solicitações de Acesso" />
									</ListItemLink>

									<ListItemLink {...linkProps('/app/arquivos/pendentes')}
										button className={classes.nested}>
										<ListItemIcon>
											<FileCopyOutlined />
										</ListItemIcon>
										<ListItemText primary="Arquivos" />
									</ListItemLink>
								</List>
							</Collapse>
						</React.Fragment>
					)}

					{Session.isAdmin() && (
						<React.Fragment>
							<ListItem button onClick={() => setConfiguracoesOpen(!configuracoesOpen)}>
								<ListItemIcon>
									<ConfiguracoesIcon />
								</ListItemIcon>
								<ListItemText primary="Configurações" />
								{configuracoesOpen ? <ExpandLess /> : <ExpandMore />}
							</ListItem>

							<Collapse in={configuracoesOpen} timeout="auto" unmountOnExit>
								<List component="div" disablePadding>
									<ListItemLink {...linkProps('/app/configuracoes/empresas')}
										button className={classes.nested}>
										<ListItemIcon>
											<EmpresasIcon />
										</ListItemIcon>
										<ListItemText primary="Empresas" />
									</ListItemLink>

									<ListItemLink {...linkProps('/app/configuracoes/usuarios')}
										button className={classes.nested}>
										<ListItemIcon>
											<UsuariosIcon />
										</ListItemIcon>
										<ListItemText primary="Usuários" />
									</ListItemLink>

									<ListItemLink {...linkProps('/app/configuracoes/processos/situacoes')}
										button className={classes.nested}>
										<ListItemIcon>
											<Gavel />
										</ListItemIcon>
										<ListItemText primary="Situações de Processo" />
									</ListItemLink>

									<ListItemLink {...linkProps('/app/configuracoes/arquivos/tipos')}
										button className={classes.nested}>
										<ListItemIcon>
											<FileCopyOutlined />
										</ListItemIcon>
										<ListItemText primary="Tipos de Arquivo" />
									</ListItemLink>
								</List>
							</Collapse>
						</React.Fragment>
					)}
				</List>
			</Drawer>

			<div className={classes.content}>
				<AppRouter />
			</div>

			{dialog}
		</React.Fragment >
	);
}

export default withRouter(App);
