import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
	Grid, Button, Typography, Divider, Table, TableHead, TableBody,
	TableRow, TableCell, Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

import { Form, TextField, Checkbox, MaskedTextField, DatePicker } from '../../../../core/components/material';
import { isValidCNPJ } from '../../../../utils/validators';
import { EmpresaAPI, Errors, filterErrors, defaultProcessCatch, tipoPessoaText, TipoUsuario } from '../../../../services/api';
import { formatCPFOrCNPJ } from '../../../../utils/formatters';
import SelectSituacaoProcesso from '../../../../components/SelectSituacaoProcesso';

const CadastroEmpresaDialog = ({ empresaUUID, onClose, onChanges }) => {
	const [modoAlteracao, setModoAlteracao] = useState(false);
	const [participantes, setParticipantes] = useState([]);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [state, setState] = useState({
		cnpj: '',
		razao_social: '',
		numero_processo: '',
		comarca: '',
		data_falencia: '',
		vara: '',
		juiz: '',
		ativo: true,
		situacao: '',
	});

	useEffect(() => {
		if (!empresaUUID) return;
		EmpresaAPI.getEmpresa(empresaUUID)
			.then(rs => {
				setState({
					cnpj: rs.cnpj,
					razao_social: rs.razao_social,
					ativo: rs.ativo,
					numero_processo: rs.numero_processo,
					comarca: rs.comarca,
					data_falencia: rs.data_falencia,
					vara: rs.vara,
					juiz: rs.juiz,
					situacao: rs.situacao,
				});
				setModoAlteracao(true);
			})
			.catch(defaultProcessCatch());

		EmpresaAPI.listarParticipantes(empresaUUID)
			.then(rs => setParticipantes(rs.participantes || []))
			.catch(defaultProcessCatch());
	}, [empresaUUID]);

	const validate = useCallback(() => {
		let errs = new Errors();
		if (state.cnpj.replace(/[^\d]+/g, '').length) {
			if (!isValidCNPJ(state.cnpj)) {
				errs.addFieldViolation('cnpj', 'Informe um CNPJ inválido');
			}
		} else {
			errs.addRequiredField('cnpj');
		}

		setErrors(errs);
		return !errs.hasErrors();
	}, [state.cnpj]);

	useEffect(() => {
		if (state.cnpj.replace(/[^\d]+/g, '').length !== 14) return;
		validate();
	}, [validate, state.cnpj]);

	const submit = () => {
		if (loading || !validate()) return;
		setLoading(true);

		if (modoAlteracao) {
			EmpresaAPI.alterarEmpresa(empresaUUID,
				{
					empresa: {
						ativo: state.ativo,
						razao_social: state.razao_social,
						numero_processo: state.numero_processo,
						comarca: state.comarca,
						data_falencia: state.data_falencia,
						vara: state.vara,
						juiz: state.juiz,
						situacao: state.situacao,
					},
				})
				.finally(() => setLoading(false))
				.then(rs => onChanges(rs))
				.catch(defaultProcessCatch(errors => setErrors(errors)));
			return;
		}

		EmpresaAPI.cadastrarEmpresa(
			{
				empresa: {
					cnpj: state.cnpj.replace(/[^\d]+/g, ''),
					razao_social: state.razao_social,
					ativo: state.ativo,
					numero_processo: state.numero_processo,
					comarca: state.comarca,
					data_falencia: state.data_falencia,
					vara: state.vara,
					juiz: state.juiz,
					situacao: state.situacao,
				}
			})
			.finally(() => setLoading(false))
			.then(rs => onChanges(rs))
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	}

	const handleChange = field => value => setState({ ...state, [field]: value });
	return (
		<Dialog open={true} onClose={onClose} maxWidth="md">
			<DialogTitle>
				{modoAlteracao ? 'Alteração' : 'Cadastro'} de Empresa
			</DialogTitle>

			<Form onSubmit={submit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<MaskedTextField label="CNPJ" required autoFocus={true}
								disabled={modoAlteracao} mask="99.999.999/9999-99"
								value={state.cnpj} onChange={handleChange('cnpj')}
								errorText={filterErrors(errors, 'cnpj')} />
						</Grid>

						<Grid item xs={4}>
							<SelectSituacaoProcesso
								label="Situação do Processo"
								value={state.situacao}
								onChange={handleChange('situacao')}
								errorText={filterErrors(errors, 'situacao')} />
						</Grid>

						<Grid item xs={12}>
							<TextField label="Razão Social" required
								value={state.razao_social}
								onChange={handleChange('razao_social')}
								errorText={filterErrors(errors, 'razao_social')} />
						</Grid>

						<Grid item xs={12}>
							<TextField label="Número do Processo"
								value={state.numero_processo}
								onChange={handleChange('numero_processo')}
								errorText={filterErrors(errors, 'numero_processo')} />
						</Grid>

						<Grid item xs={12}>
							<DatePicker label="Data de Falência" clearable
								value={state.data_falencia}
								onChange={handleChange('data_falencia')}
								errorText={filterErrors(errors, 'data_falencia')} />
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField label="Vara"
								value={state.vara}
								onChange={handleChange('vara')}
								errorText={filterErrors(errors, 'vara')} />
						</Grid>

						<Grid item xs={12} sm={6}>
							<TextField label="Comarca"
								value={state.comarca}
								onChange={handleChange('comarca')}
								errorText={filterErrors(errors, 'comarca')} />
						</Grid>

						<Grid item xs={12}>
							<TextField label="Juiz"
								value={state.juiz}
								onChange={handleChange('juiz')}
								errorText={filterErrors(errors, 'juiz')} />
						</Grid>

						<Grid item xs={12}>
							<Checkbox label="Ativo?" color="primary"
								value={state.ativo}
								onChange={handleChange('ativo')} />
						</Grid>
					</Grid>

					{modoAlteracao && (
						<React.Fragment>
							<Typography variant="h5" style={{ marginTop: 8 }}>Inscritos</Typography>
							<Divider />

							<Table style={{ marginTop: 8 }}>
								<TableHead>
									<TableRow>
										<TableCell>Nome</TableCell>
										<TableCell>E-mail</TableCell>
										<TableCell>Tipo</TableCell>
										<TableCell>Identidade</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{participantes.map((p, index) => (
										<TableRow key={index}>
											<TableCell>{p.usuario.nome_completo}</TableCell>
											<TableCell>{p.usuario.email}</TableCell>
											<TableCell>{tipoPessoaText(p.usuario.tipo)}</TableCell>
											<TableCell>
												{p.usuario.tipo === TipoUsuario.ESCRITORIO || p.usuario.tipo === TipoUsuario.ADVOGADO
													? formatCPFOrCNPJ(p.usuario.identificador)
													: p.usuario.identificador}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</React.Fragment>
					)}
				</DialogContent>

				<DialogActions>
					<Button disabled={loading} onClick={onClose}>
						Fechar
					</Button>
					<Button color="primary" variant="outlined" type="submit" disabled={loading}>
						{modoAlteracao ? 'Alterar' : 'Cadastrar'}
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

CadastroEmpresaDialog.propTypes = {
	empresaUUID: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onChanges: PropTypes.func.isRequired,
}

export default CadastroEmpresaDialog;
