import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ children, onSubmit }) => {
  const submit = event => {
    event.preventDefault();
    onSubmit();
  };
  return <form noValidate onSubmit={submit}>{children}</form>;
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

Form.defaultProps = {
  onSubmit: () => console.error('not defined onSubmit'),
}

export default Form;