import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { Grid, Button, makeStyles, Typography, Divider } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import SimplePage from '../../core/components/SimplePage';
import {
	Form, TextField, Link, MenuItem,
	SelectField, RadioGroup, RadioGroupItem, MaskedTextField,
} from '../../core/components/material';
import {
	UsuarioAPI, TipoUsuario, EmpresaAPI,
	defaultProcessCatch, filterErrors, Errors,
} from '../../services/api';
import { formatCNPJ } from '../../utils/formatters';

const useStyles = makeStyles(theme => ({
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	spacing: {
		marginTop: theme.spacing(2),
	},
	identidade: {
		'& input': {
			textTransform: 'uppercase',
		},
	},
}));

const Register = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [state, setState] = useState({
		nome: '',
		sobrenome: '',

		email: '',
		confirmacaoEmail: '',

		senha: '',
		confirmacaoSenha: '',

		empresa: '',
		tipo: TipoUsuario.INDEFINIDO,
		identidade: '',
	});

	const [empresas, setEmpresas] = useState([]);
	useState(() => {
		EmpresaAPI.listarEmpresas()
			.then(rs => setEmpresas(rs.empresas || []))
			.catch(defaultProcessCatch());
	}, []);

	const [redirect, setRedirect] = useState(null);
	if (redirect) return redirect;

	const handleChange = name => value => setState({ ...state, [name]: value });
	const submit = () => {
		if (loading) return;
		setLoading(true);

		let errors = new Errors();
		if (state.confirmacaoEmail !== state.email) {
			errors.addFieldViolation('confirmacaoEmail', 'Confirmação de e-mail não confere');
		}
		if (state.confirmacaoSenha !== state.senha) {
			errors.addFieldViolation('confirmacaoSenha', 'Confirmação de senha não confere');
		}
		if (errors.hasErrors()) {
			setLoading(false);
			setErrors(errors);
			return;
		}

		UsuarioAPI.registrarUsuario(
			{
				usuario: {
					nome: state.nome,
					sobrenome: state.sobrenome,
					email: state.email,
					tipo: state.tipo,
					identificador: state.tipo !== TipoUsuario.ADVOGADO
						? state.identidade.replace(/[^\d]+/g, '')
						: state.identidade,
				},
				senha: state.senha,
				empresa_uuid: state.empresa,
			})
			.finally(() => setLoading(false))
			.then(() => setRedirect(<Redirect to="/auth/login" />))
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	};

	const identidadeLabel = () => {
		switch (state.tipo) {
			case TipoUsuario.ESCRITORIO:
				return 'CNPJ';
			case TipoUsuario.ADVOGADO:
				return 'OAB';
			case TipoUsuario.PESSOAFISICA:
				return 'CPF';
			default:
				return 'Identidade';
		}
	}
	const identidadeMask = () => {
		switch (state.tipo) {
			case TipoUsuario.ESCRITORIO:
				return '99.999.999/9999-99';
			case TipoUsuario.PESSOAFISICA:
				return '999.999.999-99';
			default:
				return '******************';
		}
	}

	return (
		<SimplePage title="Registrar-se" maxWidth="sm" icon={<LockOutlinedIcon />}>
			<Form onSubmit={submit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField label="Nome" autoFocus={true}
							value={state.nome} onChange={handleChange('nome')}
							errorText={filterErrors(errors, ['usuario', 'usuario.nome'])} />
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField label="Sobrenome"
							value={state.sobrenome} onChange={handleChange('sobrenome')}
							errorText={filterErrors(errors, ['usuario', 'usuario.sobrenome'])} />
					</Grid>

					<Grid item xs={12}>
						<TextField label="Endereço de Email" autoComplete="email"
							value={state.email} onChange={handleChange('email')}
							errorText={filterErrors(errors, ['usuario', 'usuario.email'])} />
					</Grid>

					<Grid item xs={12}>
						<TextField label="Confirmação do Endereço de Email"
							value={state.confirmacaoEmail} onChange={handleChange('confirmacaoEmail')}
							errorText={filterErrors(errors, ['confirmacaoEmail'])} />
					</Grid>

					<Grid item xs={12}>
						<TextField label="Senha" type="password"
							value={state.senha} onChange={handleChange('senha')}
							errorText={filterErrors(errors, ['senha'])} />
					</Grid>

					<Grid item xs={12}>
						<TextField label="Confirmação de Senha" type="password"
							value={state.confirmacaoSenha} onChange={handleChange('confirmacaoSenha')}
							errorText={filterErrors(errors, ['confirmacaoSenha'])} />
					</Grid>

					<Grid item xs={12}>
						<RadioGroup label="Tipo de Pessoa" direction="row" justify="space-around"
							value={state.tipo} onChange={handleChange('tipo')}
							errorText={filterErrors(errors, ['usuario', 'usuario.tipo'])}>
							<RadioGroupItem value={TipoUsuario.ESCRITORIO} placement="end">
								Escritório
							</RadioGroupItem>
							<RadioGroupItem value={TipoUsuario.ADVOGADO} placement="end">
								Advogado
							</RadioGroupItem>
							<RadioGroupItem value={TipoUsuario.PESSOAFISICA} placement="end">
								Não informado
							</RadioGroupItem>
						</RadioGroup>
					</Grid>

					<Grid item xs={12}>
						{state.tipo === TipoUsuario.ADVOGADO
							? (
								<TextField inputProps={{ maxLength: 40 }}
									className={classes.identidade}
									label={identidadeLabel()}
									disabled={state.tipo === TipoUsuario.INDEFINIDO}
									value={state.identidade}
									onChange={handleChange('identidade')}
									errorText={filterErrors(errors, ['usuario', 'usuario.identificador'])} />
							)
							: (
								<MaskedTextField
									className={classes.identidade}
									label={identidadeLabel()}
									mask={identidadeMask()}
									disabled={state.tipo === TipoUsuario.INDEFINIDO}
									value={state.identidade}
									onChange={handleChange('identidade')}
									errorText={filterErrors(errors, ['usuario', 'usuario.identificador'])}
									helperText={state.tipo === TipoUsuario.INDEFINIDO ? 'Selecione o Tipo de Pessoa' : undefined} />
							)}
					</Grid>
				</Grid>

				<Typography gutterBottom variant="h5" className={classes.spacing}>
					Empresa
				</Typography>
				<Divider />

				<Grid container spacing={2} className={classes.spacing}>
					<Grid item xs={12}>
						<SelectField label="Empresa" value={state.empresa}
							onChange={handleChange('empresa')}
							errorText={filterErrors(errors, ['empresa', 'empresa_uuid'])}>
							{empresas.map((empresa, index) => (
								<MenuItem key={index} value={empresa.uuid}>
									{empresa.razao_social} ({empresa.numero_processo || formatCNPJ(empresa.cnpj)})
								</MenuItem>
							))}
						</SelectField>
					</Grid>
				</Grid>

				<Button className={classes.submit} type="submit"
					variant="contained" color="primary" fullWidth>
					Registrar
				</Button>
			</Form>

			<Grid container justify="flex-end">
				<Grid item>
					<Link to="/auth/login">
						Já possui uma conta? Entrar
					</Link>
				</Grid>
			</Grid>
		</SimplePage>
	);
}

export default Register;