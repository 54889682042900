export function formatCPF(cpf) {
	if (!cpf || !cpf.length || cpf.length !== 11) {
		return cpf;
	}
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatCNPJ(cnpj, fillCNPJMatriz) {
	if (!cnpj || !cnpj.length || (cnpj.length !== 8 && cnpj.length !== 14)) {
		return cnpj;
	}
	if (cnpj.length === 8)
		return cnpj.replace(/^(\d{2})(\d{3})(\d{3})$/, '$1.$2.$3') + (fillCNPJMatriz === true ? '/XXXX-XX' : '');

	return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
}

export function formatCPFOrCNPJ(cpfCnpj, fillCNPJMatriz) {
	if (!cpfCnpj || !cpfCnpj.length) {
		return cpfCnpj;
	}

	if (cpfCnpj.length === 8 || cpfCnpj.length === 14)
		return formatCNPJ(cpfCnpj, fillCNPJMatriz);

	if (cpfCnpj.length === 11)
		return formatCPF(cpfCnpj);

	return cpfCnpj;
}