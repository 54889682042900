import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ArquivosListarArquivosPage from './arquivos/ListarArquivos';
import ArquivosListaPendentesPage from './arquivos/ListaPendentes';
import ConfiguracoesListaEmpresasPage from './configuracoes/empresas/ListaEmpresas';
import ConfiguracoesListaUsuariosPage from './configuracoes/usuarios/ListaUsuarios';
import ConfiguracoesListaParticipantesPendentesPage from './configuracoes/participantes/ListaPendentes';
import ListaSituacoesProcessoPage from './configuracoes/empresas/ListaSituacoesProcesso';
import ListaTiposArquivoPage from './configuracoes/empresas/ListaTiposArquivo';

const AppRouter = () => (
	<Switch>
		<Route exact path="/app/arquivos" component={ArquivosListarArquivosPage} />
		<Route exact path="/app/arquivos/pendentes" component={ArquivosListaPendentesPage} />
		<Route exact path="/app/arquivos/:empresa" component={ArquivosListarArquivosPage} />
		<Route exact path="/app/configuracoes/empresas" component={ConfiguracoesListaEmpresasPage} />
		<Route exact path="/app/configuracoes/usuarios" component={ConfiguracoesListaUsuariosPage} />
		<Route exact path="/app/configuracoes/participantes/pendentes" component={ConfiguracoesListaParticipantesPendentesPage} />
		<Route exact path="/app/configuracoes/processos/situacoes" component={ListaSituacoesProcessoPage} />
		<Route exact path="/app/configuracoes/arquivos/tipos" component={ListaTiposArquivoPage} />

		<Redirect to="/app/arquivos" />
	</Switch>
)

export default AppRouter;
