import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
	Button, IconButton,
	Dialog, DialogTitle, DialogContent, DialogActions,
	Table, TableHead, TableRow, TableCell, TableBody, Divider, MenuItem,
} from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

import { EmpresaAPI, defaultProcessCatch, formatTimestamp } from '../../../../services/api';

import EnvioArquivoPublicoDialog from './EnvioArquivoPublicoDialog';
import prettyBytes from 'pretty-bytes';
import { SelectField } from '../../../../core/components/material';
import alerts from '../../../../utils/alerts';

const ListaArquivosPublicosDialog = ({ empresa, onClose }) => {
	const [dialog, setDialog] = useState(null);
	const [arquivos, setArquivos] = useState([]);
	const [tipos, setTipos] = useState([]);

	const fetchArquivos = useCallback(() => {
		EmpresaAPI.listarArquivosPublicos(empresa.uuid)
			.then(rs => setArquivos(rs.arquivos || []))
			.catch(defaultProcessCatch());
	}, [empresa]);

	useEffect(() => {
		fetchArquivos();
		EmpresaAPI.listarCategoriasArquivos()
			.then(rs => setTipos(rs.categorias || []))
			.catch(defaultProcessCatch());
	}, [fetchArquivos]);

	const openEnvioArquivo = () => setDialog(
		<EnvioArquivoPublicoDialog
			empresa={empresa}
			onClose={hasChanges => {
				setDialog(null);
				if (hasChanges) fetchArquivos();
			}} />
	)

	const remove = arquivo => {
		if (!window.confirm(`Tem certeza de que deseja remover o arquivo: ${arquivo.descricao}?`)) return;

		EmpresaAPI.deleteArquivoPublico(arquivo.uuid)
			.then(() => fetchArquivos())
			.catch(defaultProcessCatch());
	}

	const handleChangeTipo = arquivo => tipo => {
		EmpresaAPI.updateArquivoPublico(arquivo.uuid, { tipo })
			.then(() => {
				fetchArquivos();
				alerts.snackbars.success('Arquivo alterado com sucesso');
			})
			.catch(defaultProcessCatch());
	}

	return (
		<React.Fragment>
			<Dialog open={true} maxWidth="lg" fullWidth onClose={() => onClose()}>
				<DialogTitle>Arquivos Públicos da empresa: {empresa.razao_social}</DialogTitle>

				<DialogContent style={{ padding: 0 }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Descrição</TableCell>
								<TableCell>Nome</TableCell>
								<TableCell padding="none" style={{ width: 160 }}>Data de Envio</TableCell>
								<TableCell padding="none" style={{ width: 100 }}>Tamanho</TableCell>
								<TableCell padding="none" style={{ width: 200 }}>Tipo</TableCell>
								<TableCell style={{ width: 80 }}></TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{arquivos.length
								? arquivos.map((a, index) => (
									<TableRow key={index} hover>
										<TableCell>{a.descricao}</TableCell>
										<TableCell>{a.nome}</TableCell>
										<TableCell padding="none">{formatTimestamp(a.data_cadastro)}</TableCell>
										<TableCell padding="none">{prettyBytes(a.tamanho)}</TableCell>
										<TableCell padding="none">
											<SelectField
												size="small"
												variant="standard"
												label=""
												value={a.categoria ? a.categoria.uuid : ''}
												onChange={handleChangeTipo(a)}>
												<MenuItem value="">- Não definido -</MenuItem>
												<Divider />
												{tipos.map((t, index) => (
													<MenuItem key={index} value={t.uuid}>{t.nome}</MenuItem>
												))}
											</SelectField>
										</TableCell>
										<TableCell padding="none" align="center">
											<IconButton color="secondary" onClick={() => remove(a)}>
												<DeleteIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))
								: (
									<TableRow>
										<TableCell colSpan={4} align="center">
											Nenhum arquivo adicionado
									</TableCell>
									</TableRow>
								)}
						</TableBody>
					</Table>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={() => onClose()}>
						Fechar
					</Button>

					<Button variant="outlined" color="primary" onClick={() => openEnvioArquivo()}>
						Enviar Arquivo
					</Button>
				</DialogActions>
			</Dialog>

			{dialog}
		</React.Fragment>
	);
}

ListaArquivosPublicosDialog.propTypes = {
	empresa: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default ListaArquivosPublicosDialog;
