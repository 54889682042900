import React, { useState } from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';

import {
	Button,
	Dialog, DialogTitle, DialogContent, DialogActions,
} from '@material-ui/core';

import { Dropzone } from '../../../../core/components/material';
import { uploadLogo, EmpresaAPI, defaultProcessCatch } from '../../../../services/api';

const UploadLogoDialog = ({ empresa, onClose }) => {
	const [files, setFiles] = useState([]);
	const file = files.length > 0 ? files[0] : null;

	const submit = () => {
		if (!file) {
			alert('Selecione uma imagem para enviar');
			return;
		}

		uploadLogo(empresa.uuid, file)
			.then(() => onClose(true))
			.catch(err => {
				console.error(err);
				alert(`Erro ao enviar arquivo: ${err}`);
			});
	}

	const remover = () => {
		if (!window.confirm('Remover logo atual?')) return;

		EmpresaAPI.removerLogo(empresa.uuid)
			.then(rs => onClose(true))
			.catch(defaultProcessCatch())
	}

	return (
		<Dialog open={true} maxWidth="md" fullWidth onClose={() => onClose()}>
			<DialogTitle>Alteração de Logo: {empresa.razao_social}</DialogTitle>

			<DialogContent>
				<div style={{ margin: '0 40px' }}>
					<Dropzone
						acceptedFiles={['image/*']}
						onDrop={files => setFiles(files)}>
						{file && (
							<React.Fragment>
								<strong>Selecionado:</strong> {file.name} ({prettyBytes(file.size)})
							</React.Fragment>
						)}
					</Dropzone>
				</div>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" onClick={() => onClose()}>
					Cancelar
				</Button>

				{empresa.has_logo && (
					<Button variant="outlined" color="secondary" onClick={() => remover()}>
						Remover Logo Atual
					</Button>
				)}

				<Button variant="outlined" color="primary" onClick={() => submit()}>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	);
}

UploadLogoDialog.propTypes = {
	empresa: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default UploadLogoDialog;