import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

import { Form, TextField, Checkbox } from '../../../../core/components/material';
import { Errors, filterErrors, defaultProcessCatch, UsuarioAPI } from '../../../../services/api';

const CadastroUsuarioDialog = ({ usuarioUUID, onClose, onChanges }) => {
	const [modoAlteracao, setModoAlteracao] = useState(false);

	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState(null);
	const [state, setState] = useState({
		nome: '',
		sobrenome: '',
		email: '',
		senha: '',
		is_agente: false,
		is_admin: false,
		is_interno: false,
	});

	useEffect(() => {
		if (!usuarioUUID) return;
		UsuarioAPI.getAgente(usuarioUUID)
			.then(rs => {
				setState({
					nome: rs.nome,
					sobrenome: rs.sobrenome,
					email: rs.email,
					senha: '',
					is_agente: rs.is_agente,
					is_admin: rs.is_admin,
					is_interno: rs.is_interno,
				});
				setModoAlteracao(true);
			})
			.catch(defaultProcessCatch());
	}, [usuarioUUID]);

	const validate = () => {
		let errs = new Errors();
		// if (state.cnpj.replace(/[^\d]+/g, '').length) {
		// 	if (!isValidCNPJ(state.cnpj)) {
		// 		errs.addFieldViolation('cnpj', 'Informe um CNPJ inválido');
		// 	}
		// } else {
		// 	errs.addRequiredField('cnpj');
		// }
		setErrors(errs);
		return !errs.hasErrors();
	}

	const submit = () => {
		if (loading || !validate()) return;
		setLoading(true);

		if (modoAlteracao) {
			UsuarioAPI.atualizarAgente(usuarioUUID,
				{
					usuario: {
						email: state.email,
						nome: state.nome,
						sobrenome: state.sobrenome,
						is_agente: state.is_agente,
						is_admin: state.is_admin,
					},
					senha: state.senha,
				})
				.finally(() => setLoading(false))
				.then(rs => onChanges(rs))
				.catch(defaultProcessCatch(errors => setErrors(errors)));
			return;
		}

		UsuarioAPI.registrarAgente(
			{
				usuario: {
					email: state.email,
					nome: state.nome,
					sobrenome: state.sobrenome,
					is_agente: state.is_agente,
					is_admin: state.is_admin,
				},
				senha: state.senha,
			})
			.finally(() => setLoading(false))
			.then(rs => onChanges(rs))
			.catch(defaultProcessCatch(errors => setErrors(errors)));
	}

	const handleChange = field => value => setState({ ...state, [field]: value });
	return (
		<Dialog open={true} onClose={onClose}>
			<DialogTitle>
				{modoAlteracao ? 'Alteração' : 'Cadastro'} de Usuário
			</DialogTitle>

			<Form onSubmit={submit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField label="Nome" autoFocus={true}
								value={state.nome} onChange={handleChange('nome')}
								errorText={filterErrors(errors, ['usuario', 'usuario.nome'])} />
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField label="Sobrenome"
								value={state.sobrenome} onChange={handleChange('sobrenome')}
								errorText={filterErrors(errors, ['usuario', 'usuario.sobrenome'])} />
						</Grid>

						<Grid item xs={12}>
							<TextField label="Endereço de Email" autoComplete="email" disabled={modoAlteracao}
								value={state.email} onChange={handleChange('email')}
								errorText={filterErrors(errors, ['usuario', 'usuario.email'])} />
						</Grid>

						<Grid item xs={12}>
							<TextField label="Senha" type="password"
								value={state.senha} onChange={handleChange('senha')}
								errorText={filterErrors(errors, ['senha'])} />
						</Grid>

						<Grid item xs={12}>
							<Checkbox label="Agente?" color="primary"
								value={state.is_agente}
								onChange={handleChange('is_agente')} />
						</Grid>

						<Grid item xs={12}>
							<Checkbox label="Administrador?" color="primary"
								value={state.is_admin}
								onChange={handleChange('is_admin')} />
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button disabled={loading} onClick={onClose}>
						Fechar
					</Button>
					<Button color="primary" variant="outlined" type="submit" disabled={loading}>
						{modoAlteracao ? 'Alterar' : 'Cadastrar'}
					</Button>
				</DialogActions>
			</Form>
		</Dialog>
	);
}

CadastroUsuarioDialog.propTypes = {
	usuarioUUID: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onChanges: PropTypes.func.isRequired,
}

export default CadastroUsuarioDialog;