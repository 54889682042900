import React, { useState, useEffect } from 'react';

import {
	IconButton, Button, Tooltip,
	Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';

import {
	Visibility as ViewIcon,
	Photo as ChangeImageIcon,
	Storage as StorageIcon,
} from '@material-ui/icons';

import { EmpresaAPI, ProcessoAPI, defaultProcessCatch, getLogoLink } from '../../../../services/api';
import Page from '../../../../core/components/Page';
import { formatCNPJ } from '../../../../utils/formatters';

import CadastroEmpresaDialog from './CadastroEmpresaDialog';
import UploadLogoDialog from './UploadLogoDialog';
import ListaArquivosPublicosDialog from './ListaArquivosPublicosDialog';
import { reduceByAttr } from '../../../../utils/reducer';

const ListaEmpresas = () => {
	const [situacoes, setSituacoes] = useState({});
	const [empresas, setEmpresa] = useState([]);
	const [dialog, setDialog] = useState(null)

	const fetchEmpresas = () => {
		EmpresaAPI.listarEmpresas()
			.then(rs => setEmpresa(rs.empresas || []))
			.catch(defaultProcessCatch());
	}
	useEffect(() => {
		fetchEmpresas();
		ProcessoAPI.listarSituacoesProcessos()
			.then(rs => setSituacoes(reduceByAttr(rs.situacoes || [], 'uuid')))
			.catch(defaultProcessCatch());
	}, []);

	const openModal = uuid => setDialog(
		<CadastroEmpresaDialog
			empresaUUID={uuid}
			onClose={() => setDialog(null)}
			onChanges={() => {
				setDialog(null);
				fetchEmpresas();
			}} />
	)

	const openChangeLogoModal = empresa => setDialog(
		<UploadLogoDialog
			empresa={empresa}
			onClose={hasChanges => {
				setDialog(null);
				if (hasChanges) fetchEmpresas();
			}} />
	)

	const openArquivosPublicosModal = empresa => setDialog(
		<ListaArquivosPublicosDialog
			empresa={empresa}
			onClose={() => setDialog(null)} />
	)

	return (
		<Page title="Lista de Empresas"
			action={
				<Button variant="outlined" color="primary" onClick={() => openModal()}>
					Cadastrar Empresa
				</Button>
			}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Razão Social</TableCell>
						<TableCell style={{ width: 180 }}>Situação</TableCell>
						<TableCell padding="none" style={{ width: 210 }}>Número do Processo</TableCell>
						<TableCell padding="none" style={{ width: 150 }}>CNPJ</TableCell>
						<TableCell align="center" style={{ width: 200 }}>Logo</TableCell>
						<TableCell padding="none" style={{ width: 50 }}>Ativo?</TableCell>
						<TableCell padding="none" style={{ width: 180 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{empresas.map((empresa, index) => {
						const s = situacoes[empresa.situacao];

						return (
							<TableRow key={index} hover>
								<TableCell>{empresa.razao_social}</TableCell>
								<TableCell>{s ? s.nome : '- não definido -'}</TableCell>
								<TableCell padding="none">{empresa.numero_processo || '- não definido -'}</TableCell>
								<TableCell padding="none">{formatCNPJ(empresa.cnpj)}</TableCell>
								<TableCell padding="none" align="center">
									{empresa.has_logo
										? (
											<img alt="Logo"
												style={{ maxHeight: 45, maxWidth: 100, margin: '4px 0' }}
												src={`${getLogoLink(empresa.uuid)}?${Date.now()}`} />
										)
										: '- não informado -'}
								</TableCell>
								<TableCell padding="none">{empresa.ativo ? 'Sim' : 'Não'}</TableCell>
								<TableCell align="center" padding="none">
									<Tooltip title="Arquivos Públicos">
										<IconButton onClick={() => openArquivosPublicosModal(empresa)}>
											<StorageIcon />
										</IconButton>
									</Tooltip>

									<Tooltip title="Alterar ou Remover Logo">
										<IconButton onClick={() => openChangeLogoModal(empresa)}>
											<ChangeImageIcon />
										</IconButton>
									</Tooltip>

									<IconButton onClick={() => openModal(empresa.uuid)}>
										<ViewIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>

			{dialog}
		</Page>
	);
}

export default ListaEmpresas;
