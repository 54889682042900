import React from 'react';
import PropTypes from 'prop-types';

import { RadioGroup as StdRadioGroup, FormControl, FormLabel, FormHelperText } from '@material-ui/core';

const RadioGroup = ({ value, onChange, errorText, label, children, direction, justify, disabled }) => {
	return (
		<FormControl component="fieldset" fullWidth={true} error={!!errorText} disabled={disabled}>
			<FormLabel component="legend">{label}</FormLabel>

			<StdRadioGroup value={value || ''}
				style={{ flexDirection: direction, justifyContent: justify }}
				onChange={event => onChange(event.target.value, event)}>
				{children}
			</StdRadioGroup>

			{errorText && <FormHelperText>{errorText}</FormHelperText>}
		</FormControl>
	);
}

RadioGroup.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,

	disabled: PropTypes.bool,
	direction: PropTypes.oneOf(['column', 'row']),
	justify: PropTypes.oneOf(['space-between', 'space-evenly', 'space-around']),
	required: PropTypes.bool,
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	errorText: PropTypes.any,
}

RadioGroup.defaultProps = {
	onChange: () => { },
}

export default RadioGroup;