/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * github.com/oliverkra/wilhelm-documentos/backend/proto/config.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ArquivoRecebimento
 */
export interface ArquivoRecebimento {
    /**
     * 
     * @type {boolean}
     * @memberof ArquivoRecebimento
     */
    recebido?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArquivoRecebimento
     */
    data?: string;
}

/**
 * 
 * @export
 * @interface DocsAlterarCategoriaArquivoRequest
 */
export interface DocsAlterarCategoriaArquivoRequest {
    /**
     * 
     * @type {DocsCategoriaArquivo}
     * @memberof DocsAlterarCategoriaArquivoRequest
     */
    categoria?: DocsCategoriaArquivo;
}

/**
 * 
 * @export
 * @interface DocsAlterarEmpresaRequest
 */
export interface DocsAlterarEmpresaRequest {
    /**
     * 
     * @type {DocsEmpresa}
     * @memberof DocsAlterarEmpresaRequest
     */
    empresa?: DocsEmpresa;
}

/**
 * 
 * @export
 * @interface DocsAlterarEmpresaResponse
 */
export interface DocsAlterarEmpresaResponse {
}

/**
 * 
 * @export
 * @interface DocsAlterarSituacaoProcessoRequest
 */
export interface DocsAlterarSituacaoProcessoRequest {
    /**
     * 
     * @type {DocsSituacaoProcesso}
     * @memberof DocsAlterarSituacaoProcessoRequest
     */
    situacao?: DocsSituacaoProcesso;
}

/**
 * 
 * @export
 * @interface DocsArquivo
 */
export interface DocsArquivo {
    /**
     * 
     * @type {string}
     * @memberof DocsArquivo
     */
    uuid?: string;
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsArquivo
     */
    usuario?: DocsUsuario;
    /**
     * 
     * @type {DocsEmpresa}
     * @memberof DocsArquivo
     */
    empresa?: DocsEmpresa;
    /**
     * 
     * @type {string}
     * @memberof DocsArquivo
     */
    nome?: string;
    /**
     * 
     * @type {number}
     * @memberof DocsArquivo
     */
    tamanho?: number;
    /**
     * 
     * @type {string}
     * @memberof DocsArquivo
     */
    data_upload?: string;
    /**
     * 
     * @type {ArquivoRecebimento}
     * @memberof DocsArquivo
     */
    recebimento?: ArquivoRecebimento;
}

/**
 * 
 * @export
 * @interface DocsAtivarParticipanteResponse
 */
export interface DocsAtivarParticipanteResponse {
}

/**
 * 
 * @export
 * @interface DocsAtualizarAgenteRequest
 */
export interface DocsAtualizarAgenteRequest {
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsAtualizarAgenteRequest
     */
    usuario?: DocsUsuario;
    /**
     * 
     * @type {string}
     * @memberof DocsAtualizarAgenteRequest
     */
    senha?: string;
}

/**
 * 
 * @export
 * @interface DocsAtualizarUsuarioRequest
 */
export interface DocsAtualizarUsuarioRequest {
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsAtualizarUsuarioRequest
     */
    usuario?: DocsUsuario;
}

/**
 * 
 * @export
 * @interface DocsAuthUsuarioRequest
 */
export interface DocsAuthUsuarioRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsAuthUsuarioRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsAuthUsuarioRequest
     */
    senha?: string;
}

/**
 * 
 * @export
 * @interface DocsAuthUsuarioResponse
 */
export interface DocsAuthUsuarioResponse {
    /**
     * 
     * @type {string}
     * @memberof DocsAuthUsuarioResponse
     */
    token?: string;
}

/**
 * 
 * @export
 * @interface DocsCadastrarCategoriaArquivoRequest
 */
export interface DocsCadastrarCategoriaArquivoRequest {
    /**
     * 
     * @type {DocsCategoriaArquivo}
     * @memberof DocsCadastrarCategoriaArquivoRequest
     */
    categoria?: DocsCategoriaArquivo;
}

/**
 * 
 * @export
 * @interface DocsCadastrarEmpresaRequest
 */
export interface DocsCadastrarEmpresaRequest {
    /**
     * 
     * @type {DocsEmpresa}
     * @memberof DocsCadastrarEmpresaRequest
     */
    empresa?: DocsEmpresa;
}

/**
 * 
 * @export
 * @interface DocsCadastrarSituacaoProcessoRequest
 */
export interface DocsCadastrarSituacaoProcessoRequest {
    /**
     * 
     * @type {DocsSituacaoProcesso}
     * @memberof DocsCadastrarSituacaoProcessoRequest
     */
    situacao?: DocsSituacaoProcesso;
}

/**
 * 
 * @export
 * @interface DocsCategoriaArquivo
 */
export interface DocsCategoriaArquivo {
    /**
     * 
     * @type {string}
     * @memberof DocsCategoriaArquivo
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsCategoriaArquivo
     */
    nome?: string;
}

/**
 * 
 * @export
 * @interface DocsComarca
 */
export interface DocsComarca {
    /**
     * 
     * @type {string}
     * @memberof DocsComarca
     */
    nome?: string;
}

/**
 * 
 * @export
 * @interface DocsEmpresa
 */
export interface DocsEmpresa {
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    cnpj?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    razao_social?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocsEmpresa
     */
    ativo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    data_cadastro?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    numero_processo?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    comarca?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    data_falencia?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    vara?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    juiz?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocsEmpresa
     */
    has_logo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresa
     */
    situacao?: string;
}

/**
 * 
 * @export
 * @interface DocsEmpresaArquivo
 */
export interface DocsEmpresaArquivo {
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresaArquivo
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresaArquivo
     */
    nome?: string;
    /**
     * 
     * @type {number}
     * @memberof DocsEmpresaArquivo
     */
    tamanho?: number;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresaArquivo
     */
    data_cadastro?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsEmpresaArquivo
     */
    descricao?: string;
    /**
     * 
     * @type {DocsCategoriaArquivo}
     * @memberof DocsEmpresaArquivo
     */
    categoria?: DocsCategoriaArquivo;
}

/**
 * 
 * @export
 * @interface DocsEnviarRecuperacaoSenhaUsuarioRequest
 */
export interface DocsEnviarRecuperacaoSenhaUsuarioRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsEnviarRecuperacaoSenhaUsuarioRequest
     */
    email?: string;
}

/**
 * 
 * @export
 * @interface DocsListarAgentesResponse
 */
export interface DocsListarAgentesResponse {
    /**
     * 
     * @type {Array<DocsUsuario>}
     * @memberof DocsListarAgentesResponse
     */
    usuarios?: Array<DocsUsuario>;
}

/**
 * 
 * @export
 * @interface DocsListarArquivosPublicosResponse
 */
export interface DocsListarArquivosPublicosResponse {
    /**
     * 
     * @type {Array<DocsEmpresaArquivo>}
     * @memberof DocsListarArquivosPublicosResponse
     */
    arquivos?: Array<DocsEmpresaArquivo>;
}

/**
 * 
 * @export
 * @interface DocsListarArquivosResponse
 */
export interface DocsListarArquivosResponse {
    /**
     * 
     * @type {Array<DocsArquivo>}
     * @memberof DocsListarArquivosResponse
     */
    arquivos?: Array<DocsArquivo>;
}

/**
 * 
 * @export
 * @interface DocsListarCategoriasArquivosResponse
 */
export interface DocsListarCategoriasArquivosResponse {
    /**
     * 
     * @type {Array<DocsCategoriaArquivo>}
     * @memberof DocsListarCategoriasArquivosResponse
     */
    categorias?: Array<DocsCategoriaArquivo>;
}

/**
 * 
 * @export
 * @interface DocsListarComarcasResponse
 */
export interface DocsListarComarcasResponse {
    /**
     * 
     * @type {Array<DocsComarca>}
     * @memberof DocsListarComarcasResponse
     */
    comarcas?: Array<DocsComarca>;
}

/**
 * 
 * @export
 * @interface DocsListarEmpresasResponse
 */
export interface DocsListarEmpresasResponse {
    /**
     * 
     * @type {Array<DocsEmpresa>}
     * @memberof DocsListarEmpresasResponse
     */
    empresas?: Array<DocsEmpresa>;
}

/**
 * 
 * @export
 * @interface DocsListarMinhasEmpresasResponse
 */
export interface DocsListarMinhasEmpresasResponse {
    /**
     * 
     * @type {Array<DocsParticipante>}
     * @memberof DocsListarMinhasEmpresasResponse
     */
    participantes?: Array<DocsParticipante>;
}

/**
 * 
 * @export
 * @interface DocsListarParticipantesPendentesResponse
 */
export interface DocsListarParticipantesPendentesResponse {
    /**
     * 
     * @type {Array<DocsParticipante>}
     * @memberof DocsListarParticipantesPendentesResponse
     */
    participantes?: Array<DocsParticipante>;
}

/**
 * 
 * @export
 * @interface DocsListarParticipantesResponse
 */
export interface DocsListarParticipantesResponse {
    /**
     * 
     * @type {Array<DocsParticipante>}
     * @memberof DocsListarParticipantesResponse
     */
    participantes?: Array<DocsParticipante>;
}

/**
 * 
 * @export
 * @interface DocsListarSituacoesProcessosResponse
 */
export interface DocsListarSituacoesProcessosResponse {
    /**
     * 
     * @type {Array<DocsSituacaoProcesso>}
     * @memberof DocsListarSituacoesProcessosResponse
     */
    situacoes?: Array<DocsSituacaoProcesso>;
}

/**
 * 
 * @export
 * @interface DocsListarUsuariosResponse
 */
export interface DocsListarUsuariosResponse {
    /**
     * 
     * @type {Array<DocsUsuario>}
     * @memberof DocsListarUsuariosResponse
     */
    usuarios?: Array<DocsUsuario>;
}

/**
 * 
 * @export
 * @interface DocsMarcarArquivoRecebidoRequest
 */
export interface DocsMarcarArquivoRecebidoRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsMarcarArquivoRecebidoRequest
     */
    uuid?: string;
}

/**
 * 
 * @export
 * @interface DocsParticipante
 */
export interface DocsParticipante {
    /**
     * 
     * @type {DocsEmpresa}
     * @memberof DocsParticipante
     */
    empresa?: DocsEmpresa;
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsParticipante
     */
    usuario?: DocsUsuario;
    /**
     * 
     * @type {boolean}
     * @memberof DocsParticipante
     */
    liberado?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocsParticipante
     */
    data_cadastro?: string;
}

/**
 * 
 * @export
 * @interface DocsRecuperacaoSenhaUsuarioRequest
 */
export interface DocsRecuperacaoSenhaUsuarioRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsRecuperacaoSenhaUsuarioRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsRecuperacaoSenhaUsuarioRequest
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsRecuperacaoSenhaUsuarioRequest
     */
    nova_senha?: string;
}

/**
 * 
 * @export
 * @interface DocsRegistrarAgenteRequest
 */
export interface DocsRegistrarAgenteRequest {
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsRegistrarAgenteRequest
     */
    usuario?: DocsUsuario;
    /**
     * 
     * @type {string}
     * @memberof DocsRegistrarAgenteRequest
     */
    senha?: string;
}

/**
 * 
 * @export
 * @interface DocsRegistrarUsuarioRequest
 */
export interface DocsRegistrarUsuarioRequest {
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsRegistrarUsuarioRequest
     */
    usuario?: DocsUsuario;
    /**
     * 
     * @type {string}
     * @memberof DocsRegistrarUsuarioRequest
     */
    senha?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsRegistrarUsuarioRequest
     */
    empresa_uuid?: string;
}

/**
 * 
 * @export
 * @interface DocsRemoverLogoEmpresaResponse
 */
export interface DocsRemoverLogoEmpresaResponse {
}

/**
 * 
 * @export
 * @interface DocsRemoverParticipanteResponse
 */
export interface DocsRemoverParticipanteResponse {
}

/**
 * 
 * @export
 * @interface DocsSearchEmpresasRequest
 */
export interface DocsSearchEmpresasRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsSearchEmpresasRequest
     */
    situacao?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsSearchEmpresasRequest
     */
    comarca?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsSearchEmpresasRequest
     */
    nome?: string;
}

/**
 * 
 * @export
 * @interface DocsSearchEmpresasResponse
 */
export interface DocsSearchEmpresasResponse {
    /**
     * 
     * @type {Array<DocsEmpresa>}
     * @memberof DocsSearchEmpresasResponse
     */
    empresas?: Array<DocsEmpresa>;
}

/**
 * 
 * @export
 * @interface DocsSituacaoProcesso
 */
export interface DocsSituacaoProcesso {
    /**
     * 
     * @type {string}
     * @memberof DocsSituacaoProcesso
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsSituacaoProcesso
     */
    nome?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocsTipoUsuario {
    INDEFINIDO = <any> 'TIPO_USUARIO_INDEFINIDO',
    ESCRITORIO = <any> 'TIPO_USUARIO_ESCRITORIO',
    ADVOGADO = <any> 'TIPO_USUARIO_ADVOGADO',
    PESSOAFISICA = <any> 'TIPO_USUARIO_PESSOA_FISICA'
}

/**
 * 
 * @export
 * @interface DocsTrocarSenhaUsuarioRequest
 */
export interface DocsTrocarSenhaUsuarioRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsTrocarSenhaUsuarioRequest
     */
    senha_atual?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsTrocarSenhaUsuarioRequest
     */
    nova_senha?: string;
}

/**
 * 
 * @export
 * @interface DocsUpdateArquivoPublicoRequest
 */
export interface DocsUpdateArquivoPublicoRequest {
    /**
     * 
     * @type {string}
     * @memberof DocsUpdateArquivoPublicoRequest
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsUpdateArquivoPublicoRequest
     */
    tipo?: string;
}

/**
 * 
 * @export
 * @interface DocsUsuario
 */
export interface DocsUsuario {
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    nome?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    sobrenome?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    data_cadastro?: string;
    /**
     * 
     * @type {DocsTipoUsuario}
     * @memberof DocsUsuario
     */
    tipo?: DocsTipoUsuario;
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    identificador?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocsUsuario
     */
    is_agente?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocsUsuario
     */
    is_admin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocsUsuario
     */
    nome_completo?: string;
}

/**
 * 
 * @export
 * @interface DocsUsuarioLogadoResponse
 */
export interface DocsUsuarioLogadoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DocsUsuarioLogadoResponse
     */
    authenticated?: boolean;
    /**
     * 
     * @type {DocsUsuario}
     * @memberof DocsUsuarioLogadoResponse
     */
    usuario?: DocsUsuario;
}


/**
 * ArquivoServiceApi - fetch parameter creator
 * @export
 */
export const ArquivoServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivos(empresa_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling listarArquivos.');
            }
            const localVarPath = `/empresas/{empresa_uuid}/arquivos`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivosPendentes(options: any = {}): FetchArgs {
            const localVarPath = `/arquivos:pendentes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {DocsMarcarArquivoRecebidoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcarArquivoRecebido(uuid: string, body: DocsMarcarArquivoRecebidoRequest, options: any = {}): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling marcarArquivoRecebido.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling marcarArquivoRecebido.');
            }
            const localVarPath = `/arquivos/{uuid}:marcarRecebido`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsMarcarArquivoRecebidoRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerArquivo(uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling removerArquivo.');
            }
            const localVarPath = `/arquivos/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArquivoServiceApi - functional programming interface
 * @export
 */
export const ArquivoServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivos(empresa_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarArquivosResponse> {
            const localVarFetchArgs = ArquivoServiceApiFetchParamCreator(configuration).listarArquivos(empresa_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivosPendentes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarArquivosResponse> {
            const localVarFetchArgs = ArquivoServiceApiFetchParamCreator(configuration).listarArquivosPendentes(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {DocsMarcarArquivoRecebidoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcarArquivoRecebido(uuid: string, body: DocsMarcarArquivoRecebidoRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ArquivoServiceApiFetchParamCreator(configuration).marcarArquivoRecebido(uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerArquivo(uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ArquivoServiceApiFetchParamCreator(configuration).removerArquivo(uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ArquivoServiceApi - factory interface
 * @export
 */
export const ArquivoServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivos(empresa_uuid: string, options?: any) {
            return ArquivoServiceApiFp(configuration).listarArquivos(empresa_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivosPendentes(options?: any) {
            return ArquivoServiceApiFp(configuration).listarArquivosPendentes(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {DocsMarcarArquivoRecebidoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        marcarArquivoRecebido(uuid: string, body: DocsMarcarArquivoRecebidoRequest, options?: any) {
            return ArquivoServiceApiFp(configuration).marcarArquivoRecebido(uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerArquivo(uuid: string, options?: any) {
            return ArquivoServiceApiFp(configuration).removerArquivo(uuid, options)(fetch, basePath);
        },
    };
};

/**
 * ArquivoServiceApi - object-oriented interface
 * @export
 * @class ArquivoServiceApi
 * @extends {BaseAPI}
 */
export class ArquivoServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} empresa_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArquivoServiceApi
     */
    public listarArquivos(empresa_uuid: string, options?: any) {
        return ArquivoServiceApiFp(this.configuration).listarArquivos(empresa_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArquivoServiceApi
     */
    public listarArquivosPendentes(options?: any) {
        return ArquivoServiceApiFp(this.configuration).listarArquivosPendentes(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} uuid 
     * @param {DocsMarcarArquivoRecebidoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArquivoServiceApi
     */
    public marcarArquivoRecebido(uuid: string, body: DocsMarcarArquivoRecebidoRequest, options?: any) {
        return ArquivoServiceApiFp(this.configuration).marcarArquivoRecebido(uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArquivoServiceApi
     */
    public removerArquivo(uuid: string, options?: any) {
        return ArquivoServiceApiFp(this.configuration).removerArquivo(uuid, options)(this.fetch, this.basePath);
    }

}

/**
 * EmpresaServiceApi - fetch parameter creator
 * @export
 */
export const EmpresaServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoria_uuid 
         * @param {DocsAlterarCategoriaArquivoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarCategoriaArquivo(categoria_uuid: string, body: DocsAlterarCategoriaArquivoRequest, options: any = {}): FetchArgs {
            // verify required parameter 'categoria_uuid' is not null or undefined
            if (categoria_uuid === null || categoria_uuid === undefined) {
                throw new RequiredError('categoria_uuid','Required parameter categoria_uuid was null or undefined when calling alterarCategoriaArquivo.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling alterarCategoriaArquivo.');
            }
            const localVarPath = `/categorias-arquivos/{categoria.uuid}`
                .replace(`{${"categoria.uuid"}}`, encodeURIComponent(String(categoria_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsAlterarCategoriaArquivoRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {DocsAlterarEmpresaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarEmpresa(empresa_uuid: string, body: DocsAlterarEmpresaRequest, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling alterarEmpresa.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling alterarEmpresa.');
            }
            const localVarPath = `/empresas/{empresa.uuid}`
                .replace(`{${"empresa.uuid"}}`, encodeURIComponent(String(empresa_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsAlterarEmpresaRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ativarParticipante(empresa_uuid: string, usuario_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling ativarParticipante.');
            }
            // verify required parameter 'usuario_uuid' is not null or undefined
            if (usuario_uuid === null || usuario_uuid === undefined) {
                throw new RequiredError('usuario_uuid','Required parameter usuario_uuid was null or undefined when calling ativarParticipante.');
            }
            const localVarPath = `/empresas/{empresa_uuid}/participantes/{usuario_uuid}`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)))
                .replace(`{${"usuario_uuid"}}`, encodeURIComponent(String(usuario_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsCadastrarCategoriaArquivoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarCategoriaArquivo(body: DocsCadastrarCategoriaArquivoRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cadastrarCategoriaArquivo.');
            }
            const localVarPath = `/categorias-arquivos`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsCadastrarCategoriaArquivoRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsCadastrarEmpresaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarEmpresa(body: DocsCadastrarEmpresaRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cadastrarEmpresa.');
            }
            const localVarPath = `/empresas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsCadastrarEmpresaRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArquivoPublico(uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling deleteArquivoPublico.');
            }
            const localVarPath = `/empresas/-/arquivos/publicos/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpresa(uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling getEmpresa.');
            }
            const localVarPath = `/empresas/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivosPublicos(empresa_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling listarArquivosPublicos.');
            }
            const localVarPath = `/empresas/{empresa_uuid}/arquivos/publicos`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarCategoriasArquivos(options: any = {}): FetchArgs {
            const localVarPath = `/categorias-arquivos`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarEmpresas(options: any = {}): FetchArgs {
            const localVarPath = `/empresas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarMinhasEmpresas(options: any = {}): FetchArgs {
            const localVarPath = `/me/empresas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarParticipantes(empresa_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling listarParticipantes.');
            }
            const localVarPath = `/empresas/{empresa_uuid}/participantes`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarParticipantesPendentes(options: any = {}): FetchArgs {
            const localVarPath = `/participantes:pendentes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerLogo(empresa_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling removerLogo.');
            }
            const localVarPath = `/empresas/{empresa_uuid}/logo`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerParticipante(empresa_uuid: string, usuario_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling removerParticipante.');
            }
            // verify required parameter 'usuario_uuid' is not null or undefined
            if (usuario_uuid === null || usuario_uuid === undefined) {
                throw new RequiredError('usuario_uuid','Required parameter usuario_uuid was null or undefined when calling removerParticipante.');
            }
            const localVarPath = `/empresas/{empresa_uuid}/participantes/{usuario_uuid}`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)))
                .replace(`{${"usuario_uuid"}}`, encodeURIComponent(String(usuario_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsSearchEmpresasRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEmpresas(body: DocsSearchEmpresasRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling searchEmpresas.');
            }
            const localVarPath = `/empresas:search`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsSearchEmpresasRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitarAcessoEmpresa(empresa_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'empresa_uuid' is not null or undefined
            if (empresa_uuid === null || empresa_uuid === undefined) {
                throw new RequiredError('empresa_uuid','Required parameter empresa_uuid was null or undefined when calling solicitarAcessoEmpresa.');
            }
            const localVarPath = `/me/empresas/{empresa_uuid}`
                .replace(`{${"empresa_uuid"}}`, encodeURIComponent(String(empresa_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {DocsUpdateArquivoPublicoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArquivoPublico(uuid: string, body: DocsUpdateArquivoPublicoRequest, options: any = {}): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling updateArquivoPublico.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateArquivoPublico.');
            }
            const localVarPath = `/empresas/-/arquivos/publicos/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsUpdateArquivoPublicoRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmpresaServiceApi - functional programming interface
 * @export
 */
export const EmpresaServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} categoria_uuid 
         * @param {DocsAlterarCategoriaArquivoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarCategoriaArquivo(categoria_uuid: string, body: DocsAlterarCategoriaArquivoRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsCategoriaArquivo> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).alterarCategoriaArquivo(categoria_uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {DocsAlterarEmpresaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarEmpresa(empresa_uuid: string, body: DocsAlterarEmpresaRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsAlterarEmpresaResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).alterarEmpresa(empresa_uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ativarParticipante(empresa_uuid: string, usuario_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsAtivarParticipanteResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).ativarParticipante(empresa_uuid, usuario_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsCadastrarCategoriaArquivoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarCategoriaArquivo(body: DocsCadastrarCategoriaArquivoRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsCategoriaArquivo> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).cadastrarCategoriaArquivo(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsCadastrarEmpresaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarEmpresa(body: DocsCadastrarEmpresaRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsEmpresa> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).cadastrarEmpresa(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArquivoPublico(uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).deleteArquivoPublico(uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpresa(uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsEmpresa> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).getEmpresa(uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivosPublicos(empresa_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarArquivosPublicosResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).listarArquivosPublicos(empresa_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarCategoriasArquivos(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarCategoriasArquivosResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).listarCategoriasArquivos(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarEmpresas(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarEmpresasResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).listarEmpresas(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarMinhasEmpresas(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarMinhasEmpresasResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).listarMinhasEmpresas(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarParticipantes(empresa_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarParticipantesResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).listarParticipantes(empresa_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarParticipantesPendentes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarParticipantesPendentesResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).listarParticipantesPendentes(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerLogo(empresa_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsRemoverLogoEmpresaResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).removerLogo(empresa_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerParticipante(empresa_uuid: string, usuario_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsRemoverParticipanteResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).removerParticipante(empresa_uuid, usuario_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsSearchEmpresasRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEmpresas(body: DocsSearchEmpresasRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsSearchEmpresasResponse> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).searchEmpresas(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitarAcessoEmpresa(empresa_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsParticipante> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).solicitarAcessoEmpresa(empresa_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} uuid 
         * @param {DocsUpdateArquivoPublicoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArquivoPublico(uuid: string, body: DocsUpdateArquivoPublicoRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = EmpresaServiceApiFetchParamCreator(configuration).updateArquivoPublico(uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EmpresaServiceApi - factory interface
 * @export
 */
export const EmpresaServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} categoria_uuid 
         * @param {DocsAlterarCategoriaArquivoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarCategoriaArquivo(categoria_uuid: string, body: DocsAlterarCategoriaArquivoRequest, options?: any) {
            return EmpresaServiceApiFp(configuration).alterarCategoriaArquivo(categoria_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {DocsAlterarEmpresaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarEmpresa(empresa_uuid: string, body: DocsAlterarEmpresaRequest, options?: any) {
            return EmpresaServiceApiFp(configuration).alterarEmpresa(empresa_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ativarParticipante(empresa_uuid: string, usuario_uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).ativarParticipante(empresa_uuid, usuario_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsCadastrarCategoriaArquivoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarCategoriaArquivo(body: DocsCadastrarCategoriaArquivoRequest, options?: any) {
            return EmpresaServiceApiFp(configuration).cadastrarCategoriaArquivo(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsCadastrarEmpresaRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarEmpresa(body: DocsCadastrarEmpresaRequest, options?: any) {
            return EmpresaServiceApiFp(configuration).cadastrarEmpresa(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArquivoPublico(uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).deleteArquivoPublico(uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpresa(uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).getEmpresa(uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarArquivosPublicos(empresa_uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).listarArquivosPublicos(empresa_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarCategoriasArquivos(options?: any) {
            return EmpresaServiceApiFp(configuration).listarCategoriasArquivos(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarEmpresas(options?: any) {
            return EmpresaServiceApiFp(configuration).listarEmpresas(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarMinhasEmpresas(options?: any) {
            return EmpresaServiceApiFp(configuration).listarMinhasEmpresas(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarParticipantes(empresa_uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).listarParticipantes(empresa_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarParticipantesPendentes(options?: any) {
            return EmpresaServiceApiFp(configuration).listarParticipantesPendentes(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerLogo(empresa_uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).removerLogo(empresa_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removerParticipante(empresa_uuid: string, usuario_uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).removerParticipante(empresa_uuid, usuario_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsSearchEmpresasRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEmpresas(body: DocsSearchEmpresasRequest, options?: any) {
            return EmpresaServiceApiFp(configuration).searchEmpresas(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} empresa_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solicitarAcessoEmpresa(empresa_uuid: string, options?: any) {
            return EmpresaServiceApiFp(configuration).solicitarAcessoEmpresa(empresa_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} uuid 
         * @param {DocsUpdateArquivoPublicoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArquivoPublico(uuid: string, body: DocsUpdateArquivoPublicoRequest, options?: any) {
            return EmpresaServiceApiFp(configuration).updateArquivoPublico(uuid, body, options)(fetch, basePath);
        },
    };
};

/**
 * EmpresaServiceApi - object-oriented interface
 * @export
 * @class EmpresaServiceApi
 * @extends {BaseAPI}
 */
export class EmpresaServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} categoria_uuid 
     * @param {DocsAlterarCategoriaArquivoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public alterarCategoriaArquivo(categoria_uuid: string, body: DocsAlterarCategoriaArquivoRequest, options?: any) {
        return EmpresaServiceApiFp(this.configuration).alterarCategoriaArquivo(categoria_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {DocsAlterarEmpresaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public alterarEmpresa(empresa_uuid: string, body: DocsAlterarEmpresaRequest, options?: any) {
        return EmpresaServiceApiFp(this.configuration).alterarEmpresa(empresa_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {string} usuario_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public ativarParticipante(empresa_uuid: string, usuario_uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).ativarParticipante(empresa_uuid, usuario_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsCadastrarCategoriaArquivoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public cadastrarCategoriaArquivo(body: DocsCadastrarCategoriaArquivoRequest, options?: any) {
        return EmpresaServiceApiFp(this.configuration).cadastrarCategoriaArquivo(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsCadastrarEmpresaRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public cadastrarEmpresa(body: DocsCadastrarEmpresaRequest, options?: any) {
        return EmpresaServiceApiFp(this.configuration).cadastrarEmpresa(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public deleteArquivoPublico(uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).deleteArquivoPublico(uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public getEmpresa(uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).getEmpresa(uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public listarArquivosPublicos(empresa_uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).listarArquivosPublicos(empresa_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public listarCategoriasArquivos(options?: any) {
        return EmpresaServiceApiFp(this.configuration).listarCategoriasArquivos(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public listarEmpresas(options?: any) {
        return EmpresaServiceApiFp(this.configuration).listarEmpresas(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public listarMinhasEmpresas(options?: any) {
        return EmpresaServiceApiFp(this.configuration).listarMinhasEmpresas(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public listarParticipantes(empresa_uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).listarParticipantes(empresa_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public listarParticipantesPendentes(options?: any) {
        return EmpresaServiceApiFp(this.configuration).listarParticipantesPendentes(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public removerLogo(empresa_uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).removerLogo(empresa_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {string} usuario_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public removerParticipante(empresa_uuid: string, usuario_uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).removerParticipante(empresa_uuid, usuario_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsSearchEmpresasRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public searchEmpresas(body: DocsSearchEmpresasRequest, options?: any) {
        return EmpresaServiceApiFp(this.configuration).searchEmpresas(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} empresa_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public solicitarAcessoEmpresa(empresa_uuid: string, options?: any) {
        return EmpresaServiceApiFp(this.configuration).solicitarAcessoEmpresa(empresa_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} uuid 
     * @param {DocsUpdateArquivoPublicoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmpresaServiceApi
     */
    public updateArquivoPublico(uuid: string, body: DocsUpdateArquivoPublicoRequest, options?: any) {
        return EmpresaServiceApiFp(this.configuration).updateArquivoPublico(uuid, body, options)(this.fetch, this.basePath);
    }

}

/**
 * ProcessoServiceApi - fetch parameter creator
 * @export
 */
export const ProcessoServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} situacao_uuid 
         * @param {DocsAlterarSituacaoProcessoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarSituacaoProcesso(situacao_uuid: string, body: DocsAlterarSituacaoProcessoRequest, options: any = {}): FetchArgs {
            // verify required parameter 'situacao_uuid' is not null or undefined
            if (situacao_uuid === null || situacao_uuid === undefined) {
                throw new RequiredError('situacao_uuid','Required parameter situacao_uuid was null or undefined when calling alterarSituacaoProcesso.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling alterarSituacaoProcesso.');
            }
            const localVarPath = `/processos/situacoes/{situacao.uuid}`
                .replace(`{${"situacao.uuid"}}`, encodeURIComponent(String(situacao_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsAlterarSituacaoProcessoRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsCadastrarSituacaoProcessoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarSituacaoProcesso(body: DocsCadastrarSituacaoProcessoRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cadastrarSituacaoProcesso.');
            }
            const localVarPath = `/processos/situacoes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsCadastrarSituacaoProcessoRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarComarcas(options: any = {}): FetchArgs {
            const localVarPath = `/comarcas`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarSituacoesProcessos(options: any = {}): FetchArgs {
            const localVarPath = `/processos/situacoes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessoServiceApi - functional programming interface
 * @export
 */
export const ProcessoServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} situacao_uuid 
         * @param {DocsAlterarSituacaoProcessoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarSituacaoProcesso(situacao_uuid: string, body: DocsAlterarSituacaoProcessoRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsSituacaoProcesso> {
            const localVarFetchArgs = ProcessoServiceApiFetchParamCreator(configuration).alterarSituacaoProcesso(situacao_uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsCadastrarSituacaoProcessoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarSituacaoProcesso(body: DocsCadastrarSituacaoProcessoRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsSituacaoProcesso> {
            const localVarFetchArgs = ProcessoServiceApiFetchParamCreator(configuration).cadastrarSituacaoProcesso(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarComarcas(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarComarcasResponse> {
            const localVarFetchArgs = ProcessoServiceApiFetchParamCreator(configuration).listarComarcas(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarSituacoesProcessos(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarSituacoesProcessosResponse> {
            const localVarFetchArgs = ProcessoServiceApiFetchParamCreator(configuration).listarSituacoesProcessos(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProcessoServiceApi - factory interface
 * @export
 */
export const ProcessoServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} situacao_uuid 
         * @param {DocsAlterarSituacaoProcessoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarSituacaoProcesso(situacao_uuid: string, body: DocsAlterarSituacaoProcessoRequest, options?: any) {
            return ProcessoServiceApiFp(configuration).alterarSituacaoProcesso(situacao_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsCadastrarSituacaoProcessoRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarSituacaoProcesso(body: DocsCadastrarSituacaoProcessoRequest, options?: any) {
            return ProcessoServiceApiFp(configuration).cadastrarSituacaoProcesso(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarComarcas(options?: any) {
            return ProcessoServiceApiFp(configuration).listarComarcas(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarSituacoesProcessos(options?: any) {
            return ProcessoServiceApiFp(configuration).listarSituacoesProcessos(options)(fetch, basePath);
        },
    };
};

/**
 * ProcessoServiceApi - object-oriented interface
 * @export
 * @class ProcessoServiceApi
 * @extends {BaseAPI}
 */
export class ProcessoServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} situacao_uuid 
     * @param {DocsAlterarSituacaoProcessoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessoServiceApi
     */
    public alterarSituacaoProcesso(situacao_uuid: string, body: DocsAlterarSituacaoProcessoRequest, options?: any) {
        return ProcessoServiceApiFp(this.configuration).alterarSituacaoProcesso(situacao_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsCadastrarSituacaoProcessoRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessoServiceApi
     */
    public cadastrarSituacaoProcesso(body: DocsCadastrarSituacaoProcessoRequest, options?: any) {
        return ProcessoServiceApiFp(this.configuration).cadastrarSituacaoProcesso(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessoServiceApi
     */
    public listarComarcas(options?: any) {
        return ProcessoServiceApiFp(this.configuration).listarComarcas(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessoServiceApi
     */
    public listarSituacoesProcessos(options?: any) {
        return ProcessoServiceApiFp(this.configuration).listarSituacoesProcessos(options)(this.fetch, this.basePath);
    }

}

/**
 * UsuarioServiceApi - fetch parameter creator
 * @export
 */
export const UsuarioServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {DocsAtualizarAgenteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atualizarAgente(usuario_uuid: string, body: DocsAtualizarAgenteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'usuario_uuid' is not null or undefined
            if (usuario_uuid === null || usuario_uuid === undefined) {
                throw new RequiredError('usuario_uuid','Required parameter usuario_uuid was null or undefined when calling atualizarAgente.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling atualizarAgente.');
            }
            const localVarPath = `/agentes/{usuario.uuid}`
                .replace(`{${"usuario.uuid"}}`, encodeURIComponent(String(usuario_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsAtualizarAgenteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {DocsAtualizarUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atualizarUsuario(usuario_uuid: string, body: DocsAtualizarUsuarioRequest, options: any = {}): FetchArgs {
            // verify required parameter 'usuario_uuid' is not null or undefined
            if (usuario_uuid === null || usuario_uuid === undefined) {
                throw new RequiredError('usuario_uuid','Required parameter usuario_uuid was null or undefined when calling atualizarUsuario.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling atualizarUsuario.');
            }
            const localVarPath = `/usuarios/{usuario.uuid}`
                .replace(`{${"usuario.uuid"}}`, encodeURIComponent(String(usuario_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsAtualizarUsuarioRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsAuthUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsuario(body: DocsAuthUsuarioRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authUsuario.');
            }
            const localVarPath = `/auth/authenticate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsAuthUsuarioRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsEnviarRecuperacaoSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarRecuperacaoSenhaUsuario(body: DocsEnviarRecuperacaoSenhaUsuarioRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling enviarRecuperacaoSenhaUsuario.');
            }
            const localVarPath = `/usuarios:enviarRecuperacaoSenha`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsEnviarRecuperacaoSenhaUsuarioRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgente(usuario_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'usuario_uuid' is not null or undefined
            if (usuario_uuid === null || usuario_uuid === undefined) {
                throw new RequiredError('usuario_uuid','Required parameter usuario_uuid was null or undefined when calling getAgente.');
            }
            const localVarPath = `/agentes/{usuario_uuid}`
                .replace(`{${"usuario_uuid"}}`, encodeURIComponent(String(usuario_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsuario(usuario_uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'usuario_uuid' is not null or undefined
            if (usuario_uuid === null || usuario_uuid === undefined) {
                throw new RequiredError('usuario_uuid','Required parameter usuario_uuid was null or undefined when calling getUsuario.');
            }
            const localVarPath = `/usuarios/{usuario_uuid}`
                .replace(`{${"usuario_uuid"}}`, encodeURIComponent(String(usuario_uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarAgentes(options: any = {}): FetchArgs {
            const localVarPath = `/agentes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarUsuarios(options: any = {}): FetchArgs {
            const localVarPath = `/usuarios`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsRecuperacaoSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recuperacaoSenhaUsuario(body: DocsRecuperacaoSenhaUsuarioRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling recuperacaoSenhaUsuario.');
            }
            const localVarPath = `/usuarios:recuperacaoSenhaUsuario`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsRecuperacaoSenhaUsuarioRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsRegistrarAgenteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarAgente(body: DocsRegistrarAgenteRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling registrarAgente.');
            }
            const localVarPath = `/agentes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsRegistrarAgenteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsRegistrarUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarUsuario(body: DocsRegistrarUsuarioRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling registrarUsuario.');
            }
            const localVarPath = `/usuarios`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsRegistrarUsuarioRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocsTrocarSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trocarSenhaUsuario(body: DocsTrocarSenhaUsuarioRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling trocarSenhaUsuario.');
            }
            const localVarPath = `/me:trocarSenha`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DocsTrocarSenhaUsuarioRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuarioLogado(options: any = {}): FetchArgs {
            const localVarPath = `/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication API_KEY required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsuarioServiceApi - functional programming interface
 * @export
 */
export const UsuarioServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {DocsAtualizarAgenteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atualizarAgente(usuario_uuid: string, body: DocsAtualizarAgenteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuario> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).atualizarAgente(usuario_uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {DocsAtualizarUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atualizarUsuario(usuario_uuid: string, body: DocsAtualizarUsuarioRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuario> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).atualizarUsuario(usuario_uuid, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsAuthUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsuario(body: DocsAuthUsuarioRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsAuthUsuarioResponse> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).authUsuario(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsEnviarRecuperacaoSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarRecuperacaoSenhaUsuario(body: DocsEnviarRecuperacaoSenhaUsuarioRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).enviarRecuperacaoSenhaUsuario(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgente(usuario_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuario> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).getAgente(usuario_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsuario(usuario_uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuario> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).getUsuario(usuario_uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarAgentes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarAgentesResponse> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).listarAgentes(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarUsuarios(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsListarUsuariosResponse> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).listarUsuarios(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsRecuperacaoSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recuperacaoSenhaUsuario(body: DocsRecuperacaoSenhaUsuarioRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).recuperacaoSenhaUsuario(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsRegistrarAgenteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarAgente(body: DocsRegistrarAgenteRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuario> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).registrarAgente(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsRegistrarUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarUsuario(body: DocsRegistrarUsuarioRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuario> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).registrarUsuario(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {DocsTrocarSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trocarSenhaUsuario(body: DocsTrocarSenhaUsuarioRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).trocarSenhaUsuario(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuarioLogado(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DocsUsuarioLogadoResponse> {
            const localVarFetchArgs = UsuarioServiceApiFetchParamCreator(configuration).usuarioLogado(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsuarioServiceApi - factory interface
 * @export
 */
export const UsuarioServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {DocsAtualizarAgenteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atualizarAgente(usuario_uuid: string, body: DocsAtualizarAgenteRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).atualizarAgente(usuario_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {DocsAtualizarUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        atualizarUsuario(usuario_uuid: string, body: DocsAtualizarUsuarioRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).atualizarUsuario(usuario_uuid, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsAuthUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsuario(body: DocsAuthUsuarioRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).authUsuario(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsEnviarRecuperacaoSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarRecuperacaoSenhaUsuario(body: DocsEnviarRecuperacaoSenhaUsuarioRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).enviarRecuperacaoSenhaUsuario(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAgente(usuario_uuid: string, options?: any) {
            return UsuarioServiceApiFp(configuration).getAgente(usuario_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} usuario_uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsuario(usuario_uuid: string, options?: any) {
            return UsuarioServiceApiFp(configuration).getUsuario(usuario_uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarAgentes(options?: any) {
            return UsuarioServiceApiFp(configuration).listarAgentes(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listarUsuarios(options?: any) {
            return UsuarioServiceApiFp(configuration).listarUsuarios(options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsRecuperacaoSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recuperacaoSenhaUsuario(body: DocsRecuperacaoSenhaUsuarioRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).recuperacaoSenhaUsuario(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsRegistrarAgenteRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarAgente(body: DocsRegistrarAgenteRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).registrarAgente(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsRegistrarUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarUsuario(body: DocsRegistrarUsuarioRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).registrarUsuario(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {DocsTrocarSenhaUsuarioRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trocarSenhaUsuario(body: DocsTrocarSenhaUsuarioRequest, options?: any) {
            return UsuarioServiceApiFp(configuration).trocarSenhaUsuario(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usuarioLogado(options?: any) {
            return UsuarioServiceApiFp(configuration).usuarioLogado(options)(fetch, basePath);
        },
    };
};

/**
 * UsuarioServiceApi - object-oriented interface
 * @export
 * @class UsuarioServiceApi
 * @extends {BaseAPI}
 */
export class UsuarioServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} usuario_uuid 
     * @param {DocsAtualizarAgenteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public atualizarAgente(usuario_uuid: string, body: DocsAtualizarAgenteRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).atualizarAgente(usuario_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} usuario_uuid 
     * @param {DocsAtualizarUsuarioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public atualizarUsuario(usuario_uuid: string, body: DocsAtualizarUsuarioRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).atualizarUsuario(usuario_uuid, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsAuthUsuarioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public authUsuario(body: DocsAuthUsuarioRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).authUsuario(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsEnviarRecuperacaoSenhaUsuarioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public enviarRecuperacaoSenhaUsuario(body: DocsEnviarRecuperacaoSenhaUsuarioRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).enviarRecuperacaoSenhaUsuario(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} usuario_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public getAgente(usuario_uuid: string, options?: any) {
        return UsuarioServiceApiFp(this.configuration).getAgente(usuario_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} usuario_uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public getUsuario(usuario_uuid: string, options?: any) {
        return UsuarioServiceApiFp(this.configuration).getUsuario(usuario_uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public listarAgentes(options?: any) {
        return UsuarioServiceApiFp(this.configuration).listarAgentes(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public listarUsuarios(options?: any) {
        return UsuarioServiceApiFp(this.configuration).listarUsuarios(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsRecuperacaoSenhaUsuarioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public recuperacaoSenhaUsuario(body: DocsRecuperacaoSenhaUsuarioRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).recuperacaoSenhaUsuario(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsRegistrarAgenteRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public registrarAgente(body: DocsRegistrarAgenteRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).registrarAgente(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsRegistrarUsuarioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public registrarUsuario(body: DocsRegistrarUsuarioRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).registrarUsuario(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {DocsTrocarSenhaUsuarioRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public trocarSenhaUsuario(body: DocsTrocarSenhaUsuarioRequest, options?: any) {
        return UsuarioServiceApiFp(this.configuration).trocarSenhaUsuario(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsuarioServiceApi
     */
    public usuarioLogado(options?: any) {
        return UsuarioServiceApiFp(this.configuration).usuarioLogado(options)(this.fetch, this.basePath);
    }

}

