import React, { useState, useEffect } from 'react';

import {
	IconButton, Button, makeStyles,
	Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';

import ViewIcon from '@material-ui/icons/Visibility';

import { UsuarioAPI, defaultProcessCatch } from '../../../../services/api';
import { Checkbox } from '../../../../core/components/material';
import Page from '../../../../core/components/Page';
import CadastroUsuarioDialog from './CadastroUsuarioDialog';

const useStyles = makeStyles(theme => ({
	filtros: {
		padding: theme.spacing(2),
	},
}))

const ListaUsuarios = () => {
	const classes = useStyles();
	const [usuarios, setUsuarios] = useState([]);
	const [dialog, setDialog] = useState(null);
	const [showAll, setShowAll] = useState(false);

	const fetchUsuarios = () => {
		UsuarioAPI.listarUsuarios()
			.then(rs => setUsuarios(rs.usuarios || []))
			.catch(defaultProcessCatch());
	}
	useEffect(fetchUsuarios, []);

	const openModal = uuid => {
		setDialog(<CadastroUsuarioDialog
			usuarioUUID={uuid}
			onClose={() => setDialog(null)}
			onChanges={() => {
				setDialog(null);
				fetchUsuarios();
			}} />);
	}

	const filteredUsers = (() => {
		if (showAll) return usuarios;
		return usuarios.filter(u => u.is_agente || u.is_admin);
	})();

	return (
		<Page title="Lista de Usuários"
			action={
				<Button variant="outlined" color="primary" onClick={() => openModal()}>
					Cadastrar Usuário
				</Button>
			}>
			<div className={classes.filtros}>
				<Checkbox label="Visualizar todos Usuários" value={showAll} onChange={v => setShowAll(v)} />
			</div>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell>E-mail</TableCell>
						<TableCell align="center" style={{ width: 100 }}>Agente?</TableCell>
						<TableCell align="center" style={{ width: 100 }}>Administrador?</TableCell>
						<TableCell style={{ width: 120 }}></TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{filteredUsers.map((usuario, index) => (
						<TableRow key={index}>
							<TableCell>{usuario.nome_completo}</TableCell>
							<TableCell>{usuario.email}</TableCell>
							<TableCell align="center">{usuario.is_agente ? 'Sim' : 'Não'}</TableCell>
							<TableCell align="center">{usuario.is_admin ? 'Sim' : 'Não'}</TableCell>
							<TableCell align="center" padding="checkbox">
								<IconButton onClick={() => openModal(usuario.uuid)}>
									<ViewIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}
					{!filteredUsers.length && (
						<TableRow>
							<TableCell colSpan={5} align="center">
								Nenhum registro encontrado
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>

			{dialog}
		</Page>
	);
}

export default ListaUsuarios;