import React from 'react';
import PropTypes from 'prop-types';

import { TextField as StdTextField } from '@material-ui/core';

const TextField = ({ variant = 'outlined', value, onChange, helperText, errorText, ...props }) => {
	return (
		<StdTextField {...props}
			value={value || ''}
			variant={variant}
			margin="none"
			fullWidth={true}
			error={!!errorText}
			helperText={errorText || helperText}
			onChange={event => onChange(event.target.value, event)} />
	);
}

TextField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,

	required: PropTypes.bool,
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	helperText: PropTypes.any,
	errorText: PropTypes.any,
}

export default TextField;
